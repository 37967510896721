<template>
  <div class="app-field app-field--images">
    <label
      v-if="label"
      class="mb-2 d-block"
      style="
        color: var(--r-field-label-color);
        font-weight: 500;
        font-size: 15px;
      "
      >{{ label }} ({{ list.length }})</label
    >
    <draggable
      v-show="list && list.length > 0"
      :list="list"
      v-bind="dragOptions"
      tag="div"
      itemKey="id"
      handle=".sort-handler"
      @change="onDragChange"
    >
      <template #item="{ element: attachment }">
        <div class="d-flex align-center justify-space-between mb-3">
          <div
            class="sort-handler d-flex align-center ml-1"
            style="cursor: grab"
          >
            <RIcon>mdi-drag</RIcon>
          </div>

          <span
            v-if="!editImageMode"
            class="ml-3"
            style="cursor: pointer"
            @click="openPreviewModal(attachment.path)"
          >
            <img
              :src="getAttachmentImageSrc(attachment.path)"
              alt=""
              class="attached-image"
            />
          </span>

          <span
            v-if="editImageMode"
            class="ml-3"
            @click.stop="openEditImage(attachment)"
            style="cursor: pointer"
          >
            <img
              :src="getAttachmentImageSrc(attachment.path)"
              class="attached-image"
            />
          </span>

          <div class="app-field--images__image-details">
            <input
              v-model="attachment.title"
              type="text"
              class="app-field--images__image-input"
              placeholder="כותרת/הערה לתמונה"
              maxlength="300"
              @input="updateImageTitle"
            />
          </div>

          <div class="app-field--images__image-actions">
            <RButtonIcon icon="mdi-delete" @click="removeImage(attachment)" />
          </div>
        </div>
      </template>
    </draggable>

    <div class="d-flex align-center mt-4">
      <div
        class="ml-2 app-field--images-addMediaButtonWrapper with-camera-button"
      >
        <RButton sm icon-before="mdi-camera-outline" color="black" outlined
          >צילום</RButton
        >
        <input
          ref="camera"
          class="app-field--images-addMediaInput"
          type="file"
          accept="image/*"
          capture=""
          @change="uploadImages"
        />

        <input
          ref="camera"
          name="image"
          class="app-field--images-addMediaInput"
          type="file"
          accept="image/*"
          capture=""
          @change="uploadImages"
        />
      </div>

      <div class="ml-2 app-field--images-addMediaButtonWrapper">
        <RButton sm icon-before="mdi-folder-outline" color="black" outlined
          >בחירה מהגלריה
        </RButton>
        <input
          ref="gallery"
          class="app-field--images-addMediaInput"
          type="file"
          accept="image/*,image/heic"
          multiple
          @change="uploadImages"
        />
      </div>
    </div>

    <v-dialog
      v-if="editImage.isEditImageModalOpen"
      v-model="editImage.isEditImageModalOpen"
      :max-width="700"
      :persistent="true"
    >
      <component
        :is="editImageModalComponent"
        :is-edit-image-modal-open="editImage.isEditImageModalOpen"
        :upload="editImage.upload"
        :upload-initial="editImage.uploadInitial"
        @close-edit-image="closeEditImage"
        @update-edit-image="updateEditImage"
        :close-edit-image="closeEditImage"
        :update-edit-image="updateEditImage"
        :keep-base64="keepBase64"
        :media-url="$Reporto.globals.MEDIA_URL"
      />
    </v-dialog>

    <RModal v-model="isPreviewImageModalOpen" title="תצוגת תמונה">
      <div>
        <img
          :src="getAttachmentImageSrc(previewImageModalPath)"
          alt=""
          style="
            max-width: 100%;
            height: auto;
            max-height: 90vh;
            margin: 0 auto;
            display: block;
          "
        />
      </div>
    </RModal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import EditImageModalV3 from "@/components/EditImageModal-v3/EditImageModal.vue";
import EditImageModalOld from "@/components/editImageModal.prev/EditImageModal.vue";
import { dragOptions, allowedCompaniesForEditModalV3 } from "@/common/settings";
import { mapGetters } from "vuex";

export default {
  components: { draggable },
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    keepBase64: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Array],
      default() {
        return [];
      },
    },
    maxWidth: {
      type: [Number],
      default: 1400,
    },
    editImageMode: {
      type: Boolean,
      default: false,
    },
    imageUploadPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      previewImageModalPath: "",
      isPreviewImageModalOpen: false,
      list: [],
      canChange: false,
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
      editImage: {
        isEditImageModalOpen: false,
        upload: {},
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["companyId"]),
    media_url() {
      return this.$Reporto.globals.MEDIA_URL;
    },
    editImageModalComponent() {
      // Check if current company is allowed to see the v3 feature
      return allowedCompaniesForEditModalV3.includes(this.companyId)
        ? EditImageModalV3
        : EditImageModalOld;
    },
  },
  watch: {
    // list: {
    //   handler(val) {
    //     if (this.canChange) {
    //       this.$emit("input", val);
    //     }
    //   },
    //   deep: true
    // },
    modelValue(newVal) {
      this.list = newVal && Array.isArray(newVal) ? newVal : [];
    },
  },
  mounted() {
    this.list =
      this.modelValue && Array.isArray(this.modelValue) ? this.modelValue : [];
    setTimeout(() => {
      this.canChange = true;
    }, 10);
  },
  methods: {
    openPreviewModal(imagePath) {
      this.isPreviewImageModalOpen = true;
      this.previewImageModalPath = imagePath;
    },
    getAttachmentImageSrc(attachmentPath) {
      if (attachmentPath.length > 50) {
        return attachmentPath;
      }
      return this.media_url + attachmentPath;
    },
    removeImage(image) {
      if (confirm("בטוח למחוק?")) {
        this.list.splice(this.list.indexOf(image), 1);

        this.updateParentValue();
      }
    },
    uploadImages(event) {
      var _self = this;

      var filesSelected = event.target.files;

      if (filesSelected.length > 0) {
        for (let index = 0; index < filesSelected.length; index++) {
          let file = filesSelected[index];
          if (file.type.match("image.*")) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (e) {
              var image = new Image();
              image.onload = function () {
                // Resize the image using canvas.
                var canvas = document.createElement("canvas"),
                  max_size = _self.maxWidth,
                  width = image.width,
                  height = image.height;
                if (width > height) {
                  if (width > max_size) {
                    height *= max_size / width;
                    width = max_size;
                  }
                } else {
                  if (height > max_size) {
                    width *= max_size / height;
                    height = max_size;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext("2d").drawImage(image, 0, 0, width, height);

                // Getting base64 string;
                var resizedBase64DataUrl = canvas.toDataURL("image/jpeg");

                if (_self.keepBase64) {
                  _self.list.push({
                    initialPath: resizedBase64DataUrl,
                    path: resizedBase64DataUrl,
                    title: "",
                  });

                  // if only 1 image uploaded, open it for edit.
                  if (filesSelected.length === 1) {
                    _self.openEditImage(_self.list[_self.list.length - 1]);
                  }
                } else {
                  let loader = _self.$loading.show();

                  _self.$Utils
                    .uploadImageBase64(
                      resizedBase64DataUrl,
                      _self.imageUploadPrefix
                    )
                    .then(({ data }) => {
                      _self.list.push({
                        initialPath: data.path,
                        path: data.path,
                        title: "",
                      });
                      // if only 1 image uploaded, open it for edit.
                      if (filesSelected.length === 1) {
                        _self.openEditImage(_self.list[_self.list.length - 1]);
                      }
                      loader.hide();
                    });
                }

                _self.updateParentValue();
              };
              image.src = e.target.result;
            };
          }
        }
      }
    },
    openEditImage(upload) {
      if (!this.editImageMode) return;
      this.editImage.upload = upload;
      this.editImage.isEditImageModalOpen = true;
    },
    closeEditImage() {
      this.editImage.isEditImageModalOpen = false;
    },
    updateEditImage(new_path) {
      this.editImage.upload.path = new_path;
      this.updateParentValue();
    },
    updateParentValue() {
      if (this.canChange) {
        this.$emit("update:modelValue", this.list);
      }
    },
    updateImageTitle() {
      // console.log('image', $event);
      // image.title = $event
      this.updateParentValue();
    },
    async onDragChange() {
      this.updateParentValue();
    },
  },
};
</script>
<style lang="scss" scoped>
.attached-image {
  height: 70px;
  width: 70px;
  object-fit: cover;
  border: 1px solid #dadada;
  border-radius: 4px;
  padding: 2px;
  display: block;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.app-field--images-addMediaButtonWrapper {
  position: relative;
}

.app-field--images {
  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
}

.app-field--images-addMediaInput {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
</style>
