const colorToClassMap = {
  red: 'bg-red-600',
  orange: 'bg-orange-500',
  yellow: 'bg-yellow-400',
  green: 'bg-emerald-500',
  blue: 'bg-blue-600',
  purple: 'bg-purple-600',
  pink: 'bg-pink-500',
  brown: 'bg-amber-800',
  black: 'bg-black',
  gray: 'bg-gray-400',
  cyan: 'bg-cyan-500'
}

const allowedColors = [
  "red",
  "orange",
  "yellow",
  "green",
  "blue",
  "purple",
  "pink",
  "brown",
  "black",
  "gray",
  "cyan"
]


const txtColors = [
  { color: "", label: "מחיקת צבע" },
  {
    color: "#BFEDD2",
    label: "ירוק בהיר",
  },
  {
    color: "#FBEEB8",
    label: "צהוב בהיר",
  },
  {
    color: "#F8CAC6",
    label: "אדום בהיר",
  },
  {
    color: "#ECCAFA",
    label: "סגול בהיר",
  },
  {
    color: "#C2E0F4",
    label: "כחול בהיר",
  },
  {
    color: "#2DC26B",
    label: "ירוק",
  },
  {
    color: "#F1C40F",
    label: "צהוב",
  },
  {
    color: "#E03E2D",
    label: "אדום",
  },
  {
    color: "#B96AD9",
    label: "סגול",
  },
  {
    color: "#3598DB",
    label: "כחול",
  },
  {
    color: "#169179",
    label: "טורקיז",
  },
  {
    color: "#E67E23",
    label: "כתום",
  },
  {
    color: "#BA372A",
    label: "אדום כהה",
  },
  {
    color: "#843FA1",
    label: "סגול כהה",
  },
  {
    color: "#236FA1",
    label: "כחול כהה",
  },
  {
    color: "#ECF0F1",
    label: "אפור בהיר",
  },
  {
    color: "#CED4D9",
    label: "אפור בהיר",
  },
  {
    color: "#95A5A6",
    label: "אפור",
  },
  {
    color: "#7E8C8D",
    label: "אפור כהה",
  },
  {
    color: "#34495E",
    label: "כחול כהה",
  },
  {
    color: "#000000",
    label: "שחור",
  },
  {
    color: "#ffffff",
    label: "לבן",
  },
]

const bgColors = [
  { color: "", label: "מחיקת צבע" },
  {
    color: "#BFEDD2",
    label: "ירוק בהיר",
  },
  {
    color: "#FBEEB8",
    label: "צהוב בהיר",
  },
  {
    color: "#F8CAC6",
    label: "אדום בהיר",
  },
  {
    color: "#ECCAFA",
    label: "סגול בהיר",
  },
  {
    color: "#C2E0F4",
    label: "כחול בהיר",
  },
  {
    color: "#2DC26B",
    label: "ירוק",
  },
  {
    color: "#F1C40F",
    label: "צהוב",
  },
  {
    color: "#E03E2D",
    label: "אדום",
  },
  {
    color: "#B96AD9",
    label: "סגול",
  },
  {
    color: "#3598DB",
    label: "כחול",
  },
  {
    color: "#169179",
    label: "טורקיז",
  },
  {
    color: "#E67E23",
    label: "כתום",
  },
  {
    color: "#BA372A",
    label: "אדום כהה",
  },
  {
    color: "#843FA1",
    label: "סגול כהה",
  },
  {
    color: "#236FA1",
    label: "כחול כהה",
  },
  {
    color: "#ECF0F1",
    label: "אפור בהיר",
  },
  {
    color: "#CED4D9",
    label: "אפור בהיר",
  },
  {
    color: "#95A5A6",
    label: "אפור",
  },
  {
    color: "#7E8C8D",
    label: "אפור כהה",
  },
  {
    color: "#34495E",
    label: "כחול כהה",
  },
  {
    color: "#000000",
    label: "שחור",
  },
  {
    color: "#ffffff",
    label: "לבן",
  },
]


export { colorToClassMap, allowedColors, txtColors, bgColors }