// tinyMceLoader.js
let tinyMcePromise = null;

export function loadTinyMCE() {
	if (!tinyMcePromise) {
		tinyMcePromise = new Promise((resolve, reject) => {
			if (window.tinymce) {
				resolve(window.tinymce);
			} else {
				const script = document.createElement("script");
				script.src = "/tinymce/tinymce.min.js"; // Adjust the path if needed
				script.onload = () => resolve(window.tinymce);
				script.onerror = () => reject(new Error("Failed to load TinyMCE"));
				document.head.appendChild(script);
			}
		});
	}
	return tinyMcePromise;
}