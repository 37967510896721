<template>
  <section
    class="edit-image-toolbar flex items-center justify-between px-4 images-edit-actions my-4"
  >
    <div class="flex items-center gap-3">
      <v-btn
        v-for="option in editOptions"
        :key="option.id"
        :color="isActive(option) ? 'purple' : 'grey'"
        class="toolbar-action-btn"
        flat
        @click="onSelectOption(option)"
        :shape="isMobile() ? 'round' : ''"
        :icon="isMobile()"
      >
        <v-icon :size="isMobile() ? option.iconSize : option.iconSize - 10">{{
          option.icon
        }}</v-icon>
        <span class="label mx-2">{{ option.label }}</span>
      </v-btn>
    </div>
    <div>
      <v-btn
        v-if="!activeOption && isMobile()"
        icon
        small
        flat
        color="grey"
        class="toolbar-action-btn max-h-12 max-w-12"
        @click="emit('close-edit-image')"
      >
        <v-icon size="25">mdi-close</v-icon>
      </v-btn>
      <v-btn
        v-else-if="activeOption"
        flat
        round
        class="toolbar-finish-btn max-w-30 rounded-full"
        @click="onSelectOption(null)"
      >
        <span class="text-base"> סיום עריכה </span>
      </v-btn>
    </div>
  </section>
</template>

<script setup>
import { useEditImage } from "@/composables/useEditImage";
import { editOptions } from "@/components/EditImageModal-v3/const";
import { toolType } from "./const";
import { isMobile } from "@/common/helpers/platform";

const props = defineProps({
  imageLayerRef: Object,
});

const emit = defineEmits(["close-edit-image"]);

const {
  state: { activeOption, activeTool },
  options: { selectOption, undoAction, selectTool },
} = useEditImage();

const onSelectOption = (option) => {
  if (option?.id === "undo") {
    undoAction();
    return;
  }
  selectOption(option);

  if (option?.id === "draw" && !activeTool.value) {
    selectTool(toolType.freeDraw);
  } else if (option?.id === "text") {
    selectTool(toolType.text);
  }
};

const isActive = (option) => {
  if (option?.id === "undo") return false;
  const isActive = activeOption.value && activeOption.value?.id === option.id;
  return isActive;
};
</script>

<style scoped lang="scss">
.edit-image-toolbar-colors span {
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 4px;

  @media (max-width: 550px) {
    width: 20px;
    height: 20px;
    margin: 0 2px;
  }
}

.font-size-select {
  background: rgb(255, 255, 255);
  border: 1px solid #5e21d2;
  border-radius: 4px;
  text-align: center;
  width: 40px;
  color: #5e21d2;
  font-size: 0.75rem;
  font-weight: 500;
  position: relative;
  appearance: auto;
  cursor: pointer;
  min-height: 24px;
}

.toolbar-finish-btn {
  min-height: 48px;

  @media (min-width: 600px) {
    min-height: 30px;
    max-height: 30px;
    border: 1px solid var(--color-gray-700);
  }
}

.toolbar-action-btn {
  max-height: 48px;
  max-width: 48px;
  background-color: rgb(33, 33, 33) !important;
  .label {
    display: none;
  }
  @media (min-width: 600px) {
    max-height: 30px;
    max-width: unset;
    min-width: auto;
    background-color: var(--color-gray-300) !important;
    color: var(--color-gray-700) !important;
    .label {
      display: unset;
    }
  }
}

.toolbar-action-btn.bg-purple {
  background-color: var(--color-purple) !important;
  @media (min-width: 600px) {
    color: white !important;
  }
}
</style>
