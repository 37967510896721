<template>
  <RAlert
    v-if="!getUserCache() && hasFeature('report-statuses')"
    type="info"
    dismissible
    @dismiss="alertClicked"
  >
    <h3 class="mb-2 mt-2 font-weight-bold d-flex align-center u-gap-2">
      היי {{ displayFirstName }} 👋
      מעכשיו אפשר להגדיר סטטוס לכל דוח
    </h3>
    <div class="mb-3">
      ניתן להגדיר סטטוסים מותאמים אישית ולסמן על כל דוח את הסטטוס שלו, כך שתוכל/י לעקוב אחר התקדמות הדוחות בקלות.

      <img class="mt-3" style="max-width:250px;height:auto;background:#fff;padding:10px;border-radius:6px;" src="@/assets/images/report-statuses-banner.png" alt="סטטוסים של דוחות" />
    </div>

    <div v-if="$checkRole(['owner'])">
      <div class="mb-1">בתור בעלים חברה, את/ה יכול/ת להגדיר ולשנות את הסטטוסים למה שתרצה שהחברה תשתמש.</div>
      <RButton
      to="/company-settings"
      color="blue"
      icon-before="mdi-chart-box"
    >
      למסך ניהול סטטוסים
    </RButton>
    </div>
    <div v-else>
      אם אתה רוצה לשנות את הסטטוסים המוגדרים ניתן לבקש מהמנהל שלך לעשות זאת.
      <br>
<small>      משתמש אשר מוגדר ״בעלים חברה״ תחת מסך ״משתמשים״ הוא זה שיכול לשנות ולהתאים אותם.</small>
    </div>
  </RAlert>
</template>
<script>
import { trackEvent } from "@/services/TrackingService";
import { mapGetters } from "vuex";
import {useCompanyFeatures} from "@/composables/useCompanyFeatures";


export default {
  setup() {
    const { hasFeature } = useCompanyFeatures()
    return { hasFeature }
  },
  computed: {
    ...mapGetters("auth", ["displayFirstName", "company"]),
  },
  methods: {
    trackEvent,
    alertClicked() {
      this.addToUserCache();
    },
    getUserCache() {
      return window.localStorage.getItem("cache/hasSeenDemoAlert");
    },
    addToUserCache() {
      window.localStorage.setItem("cache/hasSeenDemoAlert", true);
    },

  },
};
</script>
