<template>
  <div class="tinymce-wrapper">
    <label v-if="label" class="mb-2 d-block r-field-label">{{ label }}</label>
    <!-- The textarea that TinyMCE will turn into a rich-text editor -->
    <textarea :ref="`editor-${randomId}`" :value="editorData"></textarea>
  </div>
</template>

<script>
import { loadTinyMCE } from "@/services/TinyMceLoader";

export default {
  name: "TinyMCEEditor",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editorInstance: null,
      editorData: "",
    };
  },
  mounted() {
    // Dynamically load the TinyMCE script from /tinymce/tinymce.min.js
    loadTinyMCE()
      .then(() => {
        this.initializeEditor();
      })
      .catch((err) => {
        console.error("Error loading TinyMCE script", err);
      });
  },
  beforeUnmount() {
    // Clean up the editor instance
    if (this.editorInstance) {
      this.editorInstance.remove();
      this.editorInstance = null;
    }
  },

  computed: {
    randomId() {
      return Math.random().toString(36).substr(2, 9);
    },
  },

  methods: {
    initializeEditor() {
      if (!window.tinymce) {
        console.error("TinyMCE not found on window");
        return;
      }

      window.tinymce.init({
        content_style: "h1.h0 { font-size: 3.4em; margin: .4em 0; }",
        target: this.$refs[`editor-${this.randomId}`],
        plugins: "lists directionality autoresize",
        toolbar:
          "bold  italic underline strikethrough forecolor backcolor paragraph heading0 heading1 heading2 bullist numlist alignright aligncenter alignleft undo redo",
        branding: false,
        menubar: false,
        max_height: 700,
        formats: {
          h0: { block: "h1", classes: "h0" },
        },
        toolbar_mode: "wrap",
        base_url: "/tinymce", // Where TinyMCE core is located
        suffix: ".min", // Suffix to use (min for minified)
        skin_url: "/tinymce/skins/ui/oxide", // Path to the skins folder

        directionality: "rtl",
        language: "he_IL",
        language_url: "/tinymce/langs/he_IL.js",

        license_key: "gpl",

        setup: (editor) => {
          this.editorInstance = editor;

          editor.on("Change KeyUp", () => {
            const content = editor.getContent();
            this.editorData = content;
            this.$emit("update:modelValue", content);
          });

          editor.ui.registry.addToggleButton("paragraph", {
            text: "P",
            tooltip: "פסקה",
            onAction: () => {
              editor.execCommand("FormatBlock", false, "p");
            },
            onSetup: (buttonApi) => {
              const handleState = () => {
                buttonApi.setActive(editor.formatter.match("p"));
              };
              editor.on("NodeChange", handleState);
              return () => {
                editor.off("NodeChange", handleState);
              };
            },
          });

          editor.ui.registry.addToggleButton("heading0", {
            text: "H0",
            tooltip: "כותרת ראשית",
            onAction: () => {
              editor.formatter.apply("h0");
            },
            onSetup: (buttonApi) => {
              const handleState = () => {
                buttonApi.setActive(editor.formatter.match("h0"));
              };
              editor.on("NodeChange", handleState);
              return () => {
                editor.off("NodeChange", handleState);
              };
            },
          });

          editor.ui.registry.addToggleButton("heading1", {
            text: "H1",
            tooltip: "כותרת 1",
            onAction: () => {
              editor.execCommand("FormatBlock", false, "h1");
            },
            onSetup: (buttonApi) => {
              const handleState = () => {
                buttonApi.setActive(editor.formatter.match("h1"));
              };
              editor.on("NodeChange", handleState);
              return () => {
                editor.off("NodeChange", handleState);
              };
            },
          });

          editor.ui.registry.addToggleButton("heading2", {
            text: "H2",
            tooltip: "כותרת 2",
            onAction: () => {
              editor.execCommand("FormatBlock", false, "h2");
            },
            onSetup: (buttonApi) => {
              const handleState = () => {
                buttonApi.setActive(editor.formatter.match("h2"));
              };
              editor.on("NodeChange", handleState);
              return () => {
                editor.off("NodeChange", handleState);
              };
            },
          });

          // editor.ui.registry.addToggleButton("heading3", {
          //   text: "H3",
          //   tooltip: "כותרת 3",
          //   onAction: () => {
          //     editor.execCommand("FormatBlock", false, "h3");
          //   },
          //   onSetup: (buttonApi) => {
          //     const handleState = () => {
          //       buttonApi.setActive(editor.formatter.match("h3"));
          //     };
          //     editor.on("NodeChange", handleState);
          //     return () => {
          //       editor.off("NodeChange", handleState);
          //     };
          //   },
          // });
        },

        // Set initial content if needed
        init_instance_callback: (editor) => {
          editor.setContent(this.modelValue || "");
        },
      });
    },
  },
};
</script>

<style lang="scss">
.tinymce-wrapper {
  .tox-tinymce {
    border-radius: 0 !important;
    border: 1px solid hsl(220, 6%, 81%);
  }

  .tox-editor-header {
    border-bottom: 1px solid hsl(220, 6%, 81%) !important;
    box-shadow: none !important;
  }

  .tox-edit-area {
    border-radius: 0 !important;

    &::before {
      border: 1px solid var(--color-purple) !important;
      border-radius: 0 !important;
      outline: none !important; /* Hide any outline, if present */
    }
  }

  .tox-toolbar__primary {
    display: flex;
  }

  .tox-statusbar {
    display: none !important;
  }

  .tox-tbtn {
    min-width: 30px;
  }
  .tox-tbtn[data-mce-name="paragraph"],
  .tox-tbtn[data-mce-name="heading0"],
  .tox-tbtn[data-mce-name="heading1"],
  .tox-tbtn[data-mce-name="heading2"],
  .tox-tbtn[data-mce-name="heading3"] {
    min-width: 36px;
    span {
      text-overflow: unset;
      font-weight: bold; /* Make text bold */
      font-size: 16px; /* Increase font size */
      position: relative;
      bottom: 2px;
    }
  }
}

.tox-toolbar__group {
  padding: 0 4px 0 5px !important;

  &:not(:first-child) {
    position: relative;
  }
}
 
.tox-toolbar__group:not(:first-child)::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0.5rem;
  bottom: 0.5rem;
  width: 1px;
  background-color: hsl(220, 6%, 81%);
}

.mce-content-body {
  direction: rtl;
}


// Disable toolbar scrolling on mobile
.tinymce-wrapper .tox .tox-toolbar--scrolling {
  overflow-x:initial;
  flex-wrap:wrap;
}
.tinymce-wrapper .tox .tox-toolbar--scrolling .tox-toolbar__group {
  display:contents;
}
.tinymce-wrapper {
  font-size: 15px;
}
.tinymce-wrapper:not(:last-child) {
  margin-bottom:18px;
}
</style>
