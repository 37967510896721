import { computed } from 'vue'
import { useStore } from 'vuex'

// List of features and their allowed company IDs
const COMPANY_FEATURES = {
  'report-statuses': [2,1,1276,186,108,121,571,544,272,4,75,1071,
    1400,
    1401,
    1402,
    1403,
    1404,
    1405,
    1406,
    1407,
    1408,
    1409,
    1410,
    1411,
    1412,
    1413,
    1414,
    1415,
    1416,
    1417,
    1418,
    1419,
    1420,
    1421,
    1422,
    1423,
    1424,
    1425,
    1426,
    1427,
    1428,
    1429,
    1430,
    1431,
    1432,
    1433,
    1434,
    1435,
    1436,
    1437,
    1438,
    1439,
    1440,
    1441,
    1442,
    1443,
    1444,
    1445,
    1446,
    1447,
    1448,
    1449,
    1450,
    1451,
    1452,
    1453,
    1454,
    1455,
    1456,
    1457,
    1458,
    1459,
    1460,
    1461,
    1462,
    1463,
    1464,
    1465,
    1466,
    1467,
    1468,
    1469,
    1470,
    1471,
    1472,
    1473,
    1474,
    1475,
    1476,
    1477,
    1478,
    1479,
    1480,
    1481,
    1482,
    1483,
    1484,
    1485,
    1486,
    1487,
    1488,
    1489,
    1490,
    1491,
    1492,
    1493,
    1494,
    1495,
    1496,
    1497,
    1498,
    1499
  ]
}

export function useCompanyFeatures() {
  const store = useStore()
  const currentCompanyId = computed(() => store.getters['auth/companyId'])

  const hasFeature = (featureSlug) => {
    const allowedCompanies = COMPANY_FEATURES[featureSlug]
    return allowedCompanies?.includes(currentCompanyId.value) ?? false
  }

  return {
    hasFeature
  }
}
