<template>
  <RSimpleCard
      :mobile-fullwidth="true"
      title="סטטוס דוחות"
      subtitle="לכל דוח תוכלו להצמיד סטטוס במערכת. כאן מגדירים את כל הסטטוסים שתרצו שיהיו לכם. בקרוב בעמוד דוחות תוכלו לסנן את הדוחות לפי סטטוסים ולראות סטטיסטיקות של כמה דוחות בוצעו, בתהליך וכו׳..."
      :loading="isLoading"
      :disabled="isLoading"
      class="mb-4"
  >
    <div class="flex flex-col gap-4 mt-4">
      <draggable
          :list="reportStatuses"
          item-key="id"
          handle=".status-handle"
          :move="() => true"
          v-bind="dragOptions"
          class="grid grid-cols-1 gap-4"
          @end="handleDragChange"
      >
        <template #item="{ element: status }">
          <div
              :class="[
              'flex items-center justify-between p-4 rounded-lg border border-gray-200 transition-shadow',
              { 'bg-gray-100': status.is_disabled }
            ]"
          >
            <div class="flex items-center gap-3 flex-1">
              <v-icon
                  icon="mdi-drag"
                  class="status-handle cursor-move text-gray-400"
              />
              <div class="relative">
                <v-menu :disabled="Boolean(status.is_disabled)">
                  <template v-slot:activator="{ props }">
                    <div
                        v-bind="props"
                        :class="[
                        'w-8 h-8 rounded-full',
                        status.colorClass,
                        { 'cursor-pointer': !status.is_disabled, 'cursor-not-allowed opacity-50': status.is_disabled }
                      ]"
                    ></div>
                  </template>

                  <v-card>
                    <v-card-text class="pa-3">
                      <div class="grid grid-cols-4 gap-2">
                        <div
                            v-for="color in allowedColors"
                            :key="color"
                            :class="['w-6 h-6 rounded-lg cursor-pointer', colorToClassMap[color]]"
                            @click="updateStatusColor(status, color)"
                        ></div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
              <input
                  type="text"
                  v-model="status.status"
                  :disabled="status.is_disabled"
                  maxlength="20"
                  :class="[
                  'font-medium border-none focus:outline-none focus:ring-2 focus:ring-purple-500 rounded px-2 py-1 w-full',
                  { 'cursor-not-allowed text-gray-400': status.is_disabled }
                ]"
                  @blur="updateStatus(status)"
              />
            </div>
            <RActions>
              <RAction
                  v-if="status.is_disabled"
                  @click="toggleStatusEnabled(status)"
                  text="הפעל סטטוס"
                  icon="mdi-toggle-switch"
                  :loading="loadingStatuses.has(status.id)"
              />
              <RAction
                  v-else
                  @click="toggleStatusEnabled(status)"
                  text="כיבוי סטטוס"
                  icon="mdi-toggle-switch"
                  :loading="loadingStatuses.has(status.id)"
              />
              <RAction
                  @click="removeStatus(status)"
                  icon="mdi-delete"
                  text="מחק סטטוס"
                  :loading="loadingStatuses.has(status.id)"
              />
            </RActions>
          </div>
        </template>
      </draggable>

      <button
          @click="openNewStatusModal"
          class="flex items-center justify-center p-4 rounded-lg border-2 border-dashed transition-colors"
          :class="[
          canAddMoreStatuses
            ? 'border-purple-300 hover:border-purple-400 cursor-pointer'
            : 'border-gray-200 cursor-not-allowed opacity-80'
        ]"
          :disabled="!canAddMoreStatuses"
      >
        <v-icon icon="mdi-plus" :class="canAddMoreStatuses ? 'text-purple-500' : 'text-gray-400'" class="mr-2"/>
        <span :class="canAddMoreStatuses ? 'text-purple-500' : 'text-gray-400'" class="font-medium">
          {{ canAddMoreStatuses ? 'הוספת סטטוס חדש' : 'הגעת למקסימום הסטטוסים האפשרי' }}
        </span>
      </button>

      <hr class="border-t border-gray-200 my-4">

      <div class="flex flex-col gap-3">
        <div class="text-sm text-gray-600 font-weight-bold">סטטוס ברירת המחדל כשיוצרים דוח חדש:</div>
        <v-select
            v-model="defaultStatusId"
            :items="activeStatuses"
            item-title="status"
            item-value="id"
            :disabled="isUpdatingDefault"
            @update:model-value="updateDefaultStatus"
            variant="outlined"
            density="compact"
            class="max-w-xs"
            bg-color="white"
            hide-details
        ></v-select>
      </div>
    </div>
  </RSimpleCard>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import draggable from 'vuedraggable'
import ReportStatusApi from '@/api/ReportStatusApi'
import {useToast} from 'vue-toastification'
import {colorToClassMap, allowedColors} from '@/common/constants'

const toast = useToast()
const isLoading = ref(false)
const isUpdatingDefault = ref(false)
const loadingStatuses = ref(new Set()) // Track loading state for individual statuses
const reportStatuses = ref([])
const defaultStatusId = ref(null)

const MAX_STATUSES = 5

// Computed property to check if we've reached the limit
const canAddMoreStatuses = computed(() =>
    reportStatuses.value.length < MAX_STATUSES
)

// Add dragOptions
const dragOptions = {
  animation: 200,
  group: 'statuses',
  disabled: false,
  ghostClass: 'ghost'
}

// Computed property for active statuses
const activeStatuses = computed(() =>
    reportStatuses.value.filter(status => !status.is_disabled)
)

const fetchStatuses = async () => {
  try {
    const statuses = await ReportStatusApi.getStatuses()
    reportStatuses.value = statuses.map(status => ({
      ...status,
      colorClass: colorToClassMap[status.color] // Map API color to Tailwind class
    }))
    // Set default status ID
    const defaultStatus = statuses.find(s => s.is_default)
    defaultStatusId.value = defaultStatus?.id || null
  } catch (error) {
    console.error('Failed to fetch statuses:', error)
  }
}

onMounted(async () => {
  try {
    isLoading.value = true


    // Fetch statuses
    await fetchStatuses()
  } catch (error) {
    console.error('Failed to fetch initial data:', error)
    toast.error('שגיאה בטעינת הנתונים')
  } finally {
    isLoading.value = false
  }
})

const handleDragChange = async (evt) => {
  try {
    isLoading.value = true
    const orderPayload = reportStatuses.value.reduce((acc, status, index) => {
      acc[index + 1] = status.id
      return acc
    }, {})

    await ReportStatusApi.updateOrder({
      reportStatusesIdsOrder: orderPayload
    })
  } catch (error) {
    console.error('Failed to update status order:', error)
    toast.error('שגיאה בעדכון סדר הסטטוסים')
    await fetchStatuses()
  } finally {
    isLoading.value = false
  }
}

const updateStatus = async (status) => {
  try {
    isLoading.value = true
    await ReportStatusApi.updateStatus({
      ...status,
      color: Object.entries(colorToClassMap).find(([_, value]) => value === status.colorClass)?.[0]
    })
  } catch (error) {
    console.error('Failed to update status:', error)
    toast.error('שגיאה בעדכון הסטטוס')
  } finally {
    isLoading.value = false
  }
}

const toggleStatusEnabled = async (status) => {
  try {
    isLoading.value = true
    status.is_disabled = !status.is_disabled
    await ReportStatusApi.updateStatus({
      ...status,
      color: Object.entries(colorToClassMap).find(([_, value]) => value === status.colorClass)?.[0]
    })
  } catch (error) {
    console.error('Failed to toggle status:', error)
    toast.error('שגיאה בעדכון מצב הסטטוס, לא ניתן לשנות סטטוס ברירת מחדל')
    status.is_disabled = !status.is_disabled // Revert on error
  } finally {
    isLoading.value = false
  }
}

const removeStatus = async (status) => {
  try {
    isLoading.value = true
    await ReportStatusApi.removeStatus(status.id)
    await fetchStatuses()
  } catch (error) {
    console.error('Failed to remove status:', error)
    toast.error('שגיאה במחיקת הסטטוס, לא ניתן למחוק סטטוס בשימוש')
  } finally {
    isLoading.value = false
  }
}

const updateDefaultStatus = async (newDefaultId) => {
  try {
    isLoading.value = true

    // First, remove default from all statuses
    for (const status of reportStatuses.value) {
      if (status.is_default && status.id !== newDefaultId) {
        await ReportStatusApi.updateStatus({
          ...status,
          is_default: false
        })
      }
    }

    // Set new default status
    const newDefaultStatus = reportStatuses.value.find(s => s.id === newDefaultId)
    if (newDefaultStatus) {
      await ReportStatusApi.updateStatus({
        ...newDefaultStatus,
        is_default: true
      })
    }

    await fetchStatuses()
  } catch (error) {
    console.error('Failed to update default status:', error)
    toast.error('שגיאה בעדכון סטטוס ברירת המחדל')
  } finally {
    isLoading.value = false
  }
}

const updateStatusColor = async (status, colorName) => {
  try {
    isLoading.value = true
    await ReportStatusApi.updateStatus({
      ...status,
      color: colorName
    })
    status.colorClass = colorToClassMap[colorName]
  } catch (error) {
    console.error('Failed to update status color:', error)
    toast.error('שגיאה בעדכון צבע הסטטוס')
  } finally {
    isLoading.value = false
  }
}

const openNewStatusModal = async () => {
  if (!canAddMoreStatuses.value) {
    toast.error('לא ני��ן להוסיף יותר מ-5 סטטוסים')
    return
  }

  try {
    isLoading.value = true
    const usedColors = reportStatuses.value.map(status =>
        Object.entries(colorToClassMap).find(([_, value]) => value === status.colorClass)?.[0]
    )
    const availableColor = allowedColors.find(color =>
        !usedColors.includes(color)
    ) || allowedColors[0]

    await ReportStatusApi.createStatus({
      status: 'סטטוס חדש',
      color: availableColor,
      is_default: false,
      is_disabled: false,
    })

    await fetchStatuses()
  } catch (error) {
    console.error('Failed to create new status:', error)
    toast.error('שגיאה ביצירת סטטוס חדש')
  } finally {
    isLoading.value = false
  }
}
</script>

<style scoped>
.status-handle {
  cursor: move;
  cursor: -webkit-grabbing;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

:deep(.v-select) {
  .v-field {
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .v-field__input {
    min-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
