<template>
  <div v-if="isLoaded" class="app-editor">
    <div class="app-editor-wrapper">
      <div id="app-editor-panel" class="app-editor-panel">
        <div class="app-editor-panel__inner">
          <div class="app-editor-panel__header">
            <div class="d-flex align-center justify-start">
              <RButtonIcon
                v-if="editorMode !== 'all'"
                icon="mdi-arrow-right"
                style="color: #fff"
                @click="editorMode = 'all'"
              />
              <RButtonIcon
                v-if="editorMode === 'all'"
                icon="mdi-cog"
                style="color: #fff"
                @click="editorMode = 'settings'"
              />
            </div>
            <div
              class="d-flex align-center justify-center font-weight-medium u-flex-1"
            >
              {{ editorTitle }}
            </div>
            <div class="d-flex align-center justify-end">
              <RButtonIcon
                icon="mdi-grid"
                style="color: #fff"
                @click="editorMode = 'all'"
              />
            </div>
          </div>

          <div class="app-editor-panel__main pa-3">
            <v-card v-if="editorMode === 'all'" flat class="bg-transparent">
              <v-list class="pa-0 bg-transparent">
                <draggable
                  :list="items"
                  handle=".v-avatar"
                  v-bind="dragOptions"
                  itemKey="id"
                  @change="onItemsDragChange"
                >
                  <template #item="{ element: item }">
                    <v-list-item
                      :key="item.id"
                      style="border: thin solid rgba(0, 0, 0, 0.12)"
                      class="v-list-item-template-editor mb-2 rounded d-flex bg-white"
                      :class="{ 'is-item-disabled': item.settings.isDisabled }"
                    >
                      <v-avatar size="25" class="ml-0">
                        <v-icon> mdi-drag</v-icon>
                      </v-avatar>

                      <v-avatar size="25" class="ml-2 mt-1">
                        <v-icon>
                          {{ ItemsUtils.getItemTypeIcon(item.type) }}
                        </v-icon>
                      </v-avatar>

                      <v-list-item-title
                        @click="openEditItem(item)"
                        v-text="item.settings.label"
                      ></v-list-item-title>

                      <v-list-item-action>
                        <RActions>
                          <RAction
                            icon="mdi-plus"
                            text="הוספת שדה מתחת"
                            @click="openAddItemModal(item.order)"
                          />
                          <RAction
                            icon="mdi-pencil"
                            text="עריכה"
                            @click="openEditItem(item)"
                          />
                          <RAction
                            icon="mdi-content-copy"
                            text="שכפול"
                            @click="duplicateItem(item)"
                          />
                          <RAction
                            icon="mdi-delete"
                            text="מחיקה"
                            @click="removeItem(item.id)"
                          />
                        </RActions>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </draggable>
              </v-list>

              <RModal v-model="isAddItemModalOpen" title="הוספת שדה חדש">
                <div class="r-add-item-modal-inner">
                  <div
                    v-for="(typesGroup, index) in typesGroups"
                    :key="index"
                    class="r-add-item-modal-group"
                  >
                    <div class="r-add-item-modal-list-title">
                      {{ typesGroup.title }}
                    </div>
                    <div class="r-add-item-modal-list">
                      <div
                        v-for="type in typesGroup.types"
                        :key="type"
                        :class="`r-add-item-modal-item color-${typesColors[type]}`"
                        @click="addItem(type, addItemAtIndex)"
                      >
                        <div class="r-add-item-modal-item-icon">
                          <v-icon color="#fff" size="15"
                            >mdi-{{ ItemsUtils.getItemType(type).icon }}</v-icon
                          >
                        </div>
                        <span class="r-add-item-modal-item-text">{{
                          ItemsUtils.getItemType(type).name
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </RModal>

              <RButton
                color="grey"
                class="mx-auto d-block"
                icon-before="mdi-plus"
                @click="openAddItemModal(items.length)"
              >
                הוספת שדה
              </RButton>
            </v-card>

            <div v-if="editorMode === 'item'" class="transparent">
              <RFieldText
                v-model="editorCurrentItem.item.settings.label"
                label="שם השדה"
                class="mb-3"
              />

              <FieldsText
                v-if="editorCurrentItem.item.type === 'text'"
                v-model="editorCurrentItem.item"
              />

              <FieldsDefectsSummaryTable
                v-if="editorCurrentItem.item.type === 'defectsSummaryTable'"
                v-model="editorCurrentItem.item"
              />

              <FieldsHeadline
                v-if="editorCurrentItem.item.type === 'headline'"
                v-model="editorCurrentItem.item"
              />

              <FieldsDefectsTable
                v-if="editorCurrentItem.item.type === 'defectsTable'"
                v-model="editorCurrentItem.item"
              />

              <FieldsMiniDefectsTable
                v-if="editorCurrentItem.item.type === 'miniDefectsTable'"
                v-model="editorCurrentItem.item"
              />

              <FieldsPriceTable
                v-if="editorCurrentItem.item.type === 'priceTable'"
                v-model="editorCurrentItem.item"
              />

              <FieldsWysiwyg
                v-if="editorCurrentItem.item.type === 'wysiwyg'"
                v-model="editorCurrentItem.item"
              />

              <FieldsSpacer
                v-if="editorCurrentItem.item.type === 'spacer'"
                v-model="editorCurrentItem.item"
              />

              <FieldsImage
                v-if="editorCurrentItem.item.type === 'image'"
                v-model="editorCurrentItem.item"
              />

              <FieldsUserSignature
                v-if="editorCurrentItem.item.type === 'userSignature'"
                v-model="editorCurrentItem.item"
              />

              <FieldsSignature
                v-if="editorCurrentItem.item.type === 'signature'"
                v-model="editorCurrentItem.item"
                :edit-content="!isExistingReport"
              />

              <FieldsSignatures
                v-if="editorCurrentItem.item.type === 'signatures'"
                v-model="editorCurrentItem.item"
                :edit-content="!isExistingReport"
              />

              <FieldsImages
                v-if="editorCurrentItem.item.type === 'images'"
                v-model="editorCurrentItem.item"
              />

              <FieldsFiles
                v-if="editorCurrentItem.item.type === 'files'"
                v-model="editorCurrentItem.item"
              />

              <FieldsSideToSideTwoTexts
                v-if="editorCurrentItem.item.type === 'sideToSideTwoTexts'"
                v-model="editorCurrentItem.item"
              />

              <FieldsRepeater
                v-if="editorCurrentItem.item.type === 'repeater'"
                v-model="editorCurrentItem.item"
                :edit-content="!isExistingReport"
              />

              <FieldsTable
                v-if="editorCurrentItem.item.type === 'table'"
                v-model="editorCurrentItem.item"
                :edit-content="true"
              />

              <FieldsHtml
                v-if="editorCurrentItem.item.type === 'html'"
                v-model="editorCurrentItem.item"
                :edit-content="!isExistingReport"
              />

              <FieldsTextChoices
                v-if="editorCurrentItem.item.type === 'textChoices'"
                v-model="editorCurrentItem.item"
                :edit-content="!isExistingReport"
              />

              <br />

              <RFieldSwitch
                v-model="editorCurrentItem.item.settings.hideFromReportEditor"
                label="הסתר כשעורכים את הדוח"
                :tooltip="'כששדה זה מסומן, בעצם לא תראו את האזור <br> כשאתם עורכים את הדוח. אבל כן תראו אותו בPDF.'"
              />

              <RFieldSwitch
                v-model="editorCurrentItem.item.settings.isDisabled"
                label="כיבוי"
                :tooltip="'כיבוי בעצם מסתיר את האזור הזה בדוח. <br> למשל אם תרצו זמנית להסתיר משהו ולא למחוק לגמרי.'"
              />

              <RFieldSwitch
                v-model="editorCurrentItem.item.settings.isPaddingsCustom"
                label="הגדר ריווח מותאם אישית"
                :tooltip="'אם לא מוגדר אישית, נשתמש בריווח הגלובאלי <br> שמוגדר בהגדרות הכלליות בגלגל השיניים מצד ימין'"
              />

              <div class="app-field d-flex u-gap-2">
                <div class="u-flex-1">
                  <RFieldText
                    v-if="editorCurrentItem.item.settings.isPaddingsCustom"
                    v-model="editorCurrentItem.item.settings.paddingTop"
                    label="ריווח מלמעלה"
                    type="number"
                    min="0"
                    max="100"
                  />
                </div>
                <div class="u-flex-1">
                  <RFieldText
                    v-if="editorCurrentItem.item.settings.isPaddingsCustom"
                    v-model="editorCurrentItem.item.settings.paddingBottom"
                    label="ריווח מלמטה"
                    type="number"
                    min="0"
                    max="100"
                  />
                </div>
              </div>

              <RButton
                class="mt-5"
                color="red"
                outlined
                icon-before="mdi-delete"
                @click="removeItem(editorCurrentItem.item.id)"
              >
                מחיקת שדה זה
              </RButton>
            </div>

            <div v-if="editorMode === 'settings'" flat class="transparent">
              <RFields>
                <RFieldText v-model="reportTemplate.name" label="שם התבנית" />
                <RFieldText
                  v-if="currentCompanyId === LIBRARY_COMPANY_ID"
                  v-model="reportTemplate.description"
                  label="תיאור התבנית"
                />
                <RFieldText
                  v-if="currentCompanyId === LIBRARY_COMPANY_ID"
                  v-model="reportTemplate.category"
                  label="קטגוריה"
                />
                <div v-if="currentCompanyId === LIBRARY_COMPANY_ID">
                  <AppFieldFile
                    v-model="reportTemplate.pdf"
                    label="PDF לדוגמא"
                    accept=".pdf"
                  />
                  <AppFieldFile
                    v-model="reportTemplate.previewPdfImage"
                    accept="image/*"
                    label="תמונת תצוגה מקדימה"
                  />
                </div>

                <RFieldText v-model="reportTemplate.title" label="כותרת הדוח" />
                <RFieldSelect
                  v-model="reportTemplate.preference_id"
                  label="עיצוב"
                  :items="preferencesOptions"
                />

                <RFieldSelect
                  v-model="reportTemplate.stock_id"
                  label="מאגר"
                  :items="stocksOptions"
                />
                <div class="app-field d-flex u-gap-2">
                  <div class="u-flex-1">
                    <RFieldText
                      v-model="reportTemplate.itemPaddingTop"
                      label="ריווח מלמעלה"
                      type="number"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="u-flex-1">
                    <RFieldText
                      v-model="reportTemplate.itemPaddingBottom"
                      label="ריווח מלמטה"
                      type="number"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
                <RFieldSwitch
                  v-model="reportTemplate.enableDefectStatus"
                  label="הפעלת סטטוס ממצא"
                />

                <RFieldSwitch
                  v-model="reportTemplate.enableDefectRecheckStatus"
                  label="הפעלת סטטוס בדיקה חוזרת"
                />

                <RFieldSwitch
                  v-if="currentCompanyId === LIBRARY_COMPANY_ID"
                  v-model="reportTemplate.isDisabled"
                  label="כיבוי התבנית באופן זמני"
                />

                <RFieldSwitch
                    v-if="currentCompanyId === LIBRARY_COMPANY_ID"
                    v-model="reportTemplate.addByDefault"
                    label="הוספת תבנית לחברות חדשות"
                    :tooltip="'אם תסמנו את האפשרות הזו, תבנית זו תתווסף <br> באופן אוטומטי לחברות חדשות שתיצרו.'"
                />
              </RFields>

              <div class="d-flex mt-9">
                <RButton
                  v-if="!isExistingReport"
                  color="red"
                  outlined
                  class="ml-3"
                  icon-before="mdi-delete"
                  @click="removeReportTemplate"
                >
                  מחיקת תבנית זו
                </RButton>

                <RButton
                  v-if="!isExistingReport"
                  outlined
                  color="black"
                  icon-before="mdi-key"
                  :to="{
                    name: 'report-templates.permissions',
                    params: { id: reportTemplate.id },
                  }"
                >
                  הרשאות תבנית
                </RButton>
              </div>
            </div>
          </div>

          <div class="app-editor-panel__footer">
            <placeholders-button />

            <RButton
              color="green"
              :disabled="!shouldSave"
              @click.stop="saveReportTemplate"
            >
              שמירה
            </RButton>
          </div>
        </div>
      </div>

      <div id="app-editor-preview" class="app-editor-preview">
        <div class="app-editor-preview-container">
          <RAlert v-if="isExistingReport" type="warning">
            <div style="font-weight: 600">
              שימו לב, אתם עורכים תבנית של דוח ספציפי.
            </div>

            <p>מה שתשנו כאן ישתנה רק לדוח הזה ולא לכל הדוחות האחרים.</p>

            <div class="mt-2">
              הדוח שאתם עורכים כרגע:
              {{ reportTemplate.address }}.

              <RButton
                sm
                color="black"
                class="mr-2"
                :to="{
                  name: 'reports.edit',
                  params: { id: reportTemplate.id },
                }"
              >
                חזרה לעריכת הדוח
              </RButton>

              <RButton
                v-if="$checkRole(['admin', 'owner'])"
                sm
                color="black"
                class="mr-2"
                @click="saveAsTemplateModal.isOpen = true"
              >
                שמירת הדוח כתבנית חדשה
              </RButton>
            </div>
          </RAlert>

          <v-card
            id="app-editor-preview-box"
            outlined
            class="pa-0 d-flex app-editor-preview-box"
            style="height: auto"
          >
            <v-card-text
              class="px-4 py-4 d-flex flex-wrap justify-space-between"
              style="width: 100%"
            >
              <ReportTemplatePreview
                :report-template="reportTemplate"
                :editor-mode="editorMode"
                :editor-current-item="editorCurrentItem"
                @open-edit-item="openEditItem"
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>

    <RModal v-model="saveAsTemplateModal.isOpen" title="שמירת הדוח כתבנית חדשה">
      <RFields>
        <RFieldText
          v-model="saveAsTemplateModal.templateName"
          label="שם התבנית שניצור מהדוח הזה"
        />
        <RButton
          color="primary"
          block
          lg
          type="submit"
          :loading="saveAsTemplateModal.isLoading"
          @click="saveAsTemplate"
        >
          יצירת תבנית חדשה
        </RButton>
      </RFields>
    </RModal>
  </div>
</template>

<script>
import { ItemsUtils, Utils } from "@/common/Utils";
import { dragOptions } from "@/common/settings";
import PlaceholdersButton from "./PlaceholdersButton";
import ReportTemplatePreview from "./ReportTemplatePreview";
import FieldsSignature from "./FieldsSignature";
import FieldsSignatures from "./FieldsSignatures";
import FieldsImages from "./FieldsImages";
import FieldsFiles from "./FieldsFiles";
import FieldsImage from "./FieldsImage";
import FieldsSideToSideTwoTexts from "./FieldsSideToSideTwoTexts";
import FieldsSpacer from "./FieldsSpacer";
import FieldsWysiwyg from "./FieldsWysiwyg";
import FieldsPriceTable from "./FieldsPriceTable";
import FieldsDefectsTable from "./FieldsDefectsTable";
import FieldsHeadline from "./FieldsHeadline";
import FieldsDefectsSummaryTable from "./FieldsDefectsSummaryTable";
import FieldsText from "./FieldsText";
import FieldsTable from "./FieldsTable";
import FieldsRepeater from "./FieldsRepeater";
import FieldsUserSignature from "./FieldsUserSignature";
import FieldsMiniDefectsTable from "./FieldsMiniDefectsTable";
import FieldsHtml from "./FieldsHtml";
import FieldsTextChoices from "./FieldsTextChoices";
import { mapGetters } from "vuex";
import ReportsApi from "@/api/ReportsApi";
import draggable from "vuedraggable";
import { LIBRARY_COMPANY_ID } from "@/common/settings";
import ReportTemplatesApi from "@/api/ReportTemplatesApi";
import AppFieldFile from "./components/AppFieldFile";

export default {
  components: {
    draggable,
    FieldsRepeater,
    FieldsUserSignature,
    FieldsSignatures,
    FieldsTable,
    FieldsText,
    FieldsDefectsSummaryTable,
    FieldsHeadline,
    FieldsDefectsTable,
    FieldsMiniDefectsTable,
    FieldsPriceTable,
    FieldsWysiwyg,
    FieldsSpacer,
    FieldsSideToSideTwoTexts,
    FieldsImages,
    FieldsFiles,
    FieldsImage,
    FieldsSignature,
    FieldsHtml,
    FieldsTextChoices,
    PlaceholdersButton,
    ReportTemplatePreview,
    AppFieldFile
  },
  props: {
    isExistingReport: {
      type: Boolean,
      default: false,
    },
    reportTemplateParent: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      LIBRARY_COMPANY_ID,
      dragOptions,
      ItemsUtils,
      items: [],
      isAddItemModalOpen: false,
      addItemAtIndex: 0,
      typesColors: {
        wysiwyg: "green",
        images: "blue",
        repeater: "pink",
        table: "orangered",
        files: "red",
        sideToSideTwoTexts: "darkblue",
        textChoices: "lightblue",
        pageBreak: "lightpink",
        image: "purple",
        defectsTable: "yellow",
        priceTable: "orange",
        miniDefectsTable: "darkpurple",
        defectsSummaryTable: "green2",
        userSignature: "pink2",
        signatures: "blue2",
      },
      typesGroups: [
        {
          title: "שדות פופולריים",
          types: ["wysiwyg", "images", "repeater", "table"],
        },
        {
          title: "שדות נוספים",
          types: [
            "files",
            "sideToSideTwoTexts",
            "textChoices",
            "pageBreak",
            "image",
            // "text", // currently I think it's not needed
            "userSignature",
            "signatures",
            // "spacer",
          ],
        },
        {
          title: "שדות מאגר ממצאים/ליקויים",
          types: [
            "defectsTable",
            "priceTable",
            "miniDefectsTable",
            "defectsSummaryTable",
          ],
        },
      ],
      editorMode: "all",
      isLoaded: false,
      reportTemplateBeforeLastSave: null,
      tab: 1,
      reportTemplate: {
        data: {
          items: {},
        },
      },

      editorCurrentItem: {
        isOpen: false,
        loaded: false,
        item: {
          settings: {},
        },
      },

      saveAsTemplateModal: {
        templateName: null,
        isOpen: false,
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapGetters("stocks", ["stocks"]),
    ...mapGetters("preferences", ["preferences"]),
    ...mapGetters("auth", ["currentCompanyId"]),
    shouldSave() {
      return (
        JSON.stringify(this.reportTemplate) !==
        JSON.stringify(this.reportTemplateBeforeLastSave)
      );
    },
    preferencesOptions() {
      return this.preferences.map((preference) => {
        return {
          value: preference.id,
          text: preference.name,
        };
      });
    },
    stocksOptions() {
      let stocksOptions = this.stocks.map((stock) => {
        return {
          value: stock.id,
          text: stock.name,
        };
      });

      stocksOptions.unshift({
        value: "",
        text: "ללא מאגר",
      });

      return stocksOptions;
    },
    editorTitle() {
      if (this.editorMode === "all") {
        return "מבנה התבנית";
      } else if (this.editorMode === "item") {
        return (
          "עריכת " +
          ItemsUtils.getItemType(this.editorCurrentItem.item.type).name
        );
      } else if (this.editorMode === "settings") {
        return "הגדרות כלליות";
      }
      return "עריכה";
    },
  },
  watch: {
    shouldSave(val) {
      this.$emit("update-should-save", val);
    },
    // editorCurrentItem: {
    //   handler(val) {
    //     console.log("val", val);
    //   },
    //   deep: true,
    // },
  },
  async created() {
    let loader = this.$loading.show();

    await this.$store.dispatch("stocks/fetchStocks");
    await this.$store.dispatch("preferences/fetchPreferences");
    this.reportTemplate = this.reportTemplateParent;
    this.reportTemplateBeforeLastSave = JSON.parse(
      JSON.stringify(this.reportTemplate)
    );

    this.fixReportTemplateSchema();
    if (this.reportTemplate.name) {
      this.saveAsTemplateModal.templateName = this.reportTemplate.name;
    }

    this.initItems();
    this.isLoaded = true;
    loader.hide();
  },
  methods: {
    async saveAsTemplate() {
      if (!this.saveAsTemplateModal.templateName) {
        this.$toast.error("חובה להזין שם תבנית לשמירה");
        return;
      }

      this.saveAsTemplateModal.isLoading = true;

      try {
        const data = await ReportsApi.saveAsTemplate(
          this.reportTemplate.id,
          this.saveAsTemplateModal.templateName
        );
        this.$toast.success("שמרנו את הדוח הזה כתבנית חדשה במערכת.");

        this.saveAsTemplateModal.isLoading = false;

        await this.$router.push({
          name: "report-templates.edit",
          params: { id: data.id },
        });
      } catch (error) {
        console.log("error", error);

        this.saveAsTemplateModal.isLoading = false;
        this.$toast.error(
          error.response.data.message ? error.response.data.message : error
        );
      }
    },
    fixReportTemplateSchema() {
      if (!this.reportTemplate.itemPaddingTop) {
        this.reportTemplate.itemPaddingTop = 0;
      }
      if (!this.reportTemplate.itemPaddingBottom) {
        this.reportTemplate.itemPaddingBottom = 0;
      }
    },
    saveReportTemplate() {
      this.$emit("update-report", this.reportTemplate);
      this.reportTemplateBeforeLastSave = JSON.parse(
        JSON.stringify(this.reportTemplate)
      );
    },
    async removeReportTemplate() {
      if (confirm("בטוח למחוק את התבנית הזו?")) {
        let loader = this.$loading.show();
        await ReportTemplatesApi.removeReportTemplate(this.reportTemplate.id);
        loader.hide();
        await this.$router.push("/report-templates");
      }
    },
    addItem(itemType, addItemAtIndex) {
      this.isAddItemModalOpen = false;

      let itemTypeObject = ItemsUtils.getItemType(itemType);

      let newItem = {
        id: Utils.guid(),
        type: itemType,
        settings: { ...itemTypeObject.settings },
        order: addItemAtIndex + 0.1,
      };

      this.reportTemplate.data.items[newItem.id] = newItem;
      this.fixItemsOrder();
      this.initItems();
    },
    initItems() {
      this.items = Utils.convertObjectToArrayWithOrder(
        this.reportTemplate.data.items
      );
    },
    fixItemsOrder() {
      this.reportTemplate.data.items = Utils.convertArrayToObject(
        Utils.convertObjectToArrayWithOrder(this.reportTemplate.data.items)
      );
    },
    removeItem(itemId) {
      if (confirm("בטוח למחוק את השדה?")) {
        delete this.reportTemplate.data.items[itemId];

        this.fixItemsOrder();
        this.initItems();

        this.editorMode = "all";
      }
    },
    openEditItem(item) {
      this.editorMode = "all";
      this.$nextTick(() => {
        this.editorCurrentItem.item = item;
        this.editorMode = "item";
      });
    },
    duplicateItem(item) {
      let newItem = {
        ...item,
        id: Utils.guid(),
        order: item.order + 0.1,
      };

      newItem = JSON.parse(JSON.stringify(newItem));

      this.reportTemplate.data.items[newItem.id] = newItem;
      this.fixItemsOrder();
      this.initItems();
    },
    openAddItemModal(addItemAtIndex) {
      this.addItemAtIndex = addItemAtIndex;
      this.isAddItemModalOpen = true;
    },
    onItemsDragChange() {
      this.items = [...this.items].map((item, index) => {
        const newSort = index + 1;
        let hasChanged = item.order !== newSort;
        if (hasChanged) {
          item.order = newSort;
        }
        return item;
      });

      for (const item of this.items) {
        this.reportTemplate.data.items[item.id] = item;
      }
    },
  },
};
</script>

<style lang="scss">
.v-list-item-template-editor {
  transition: 0.2s;
  .v-list-item__content {
    display: flex;
    align-items: center;
    width: 100%;
    .v-list-item-title {
      flex-grow: 2;
    }
  }
}

.tableColumnOptions-input {
  padding: 4px 5px;
  border: thin solid rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  background: #fff;
}
</style>
