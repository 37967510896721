export const allowedCompaniesForNewOfflineSystem = [1276]; // 2 is me
export const allowedCompaniesForCameraButton = [1276, 2]; // 272 is david
export const allowedCompaniesForEditModalV3 = [1276, 2]; // 272 is david
export const allowedCompaniesForVue3 = [] // [1276, 2, 83, 1127, 1020, 177, 1371, 1, 1300, 982, 719, 41]; // Add companies that should use Vue 3
export const USE_NEW_OFFLINE_SYSTEM = true;
export const allowedCompaniesForReportStatuses = [2];

export const appDateFormat = "DD/MM/YYYY";
export const appDateTimeFormat = "DD/MM/YYYY - HH:mm";
export const supportChatUrl =
  "https://api.whatsapp.com/send?phone=972545475259&text=%D7%94%D7%99%D7%99%20%D7%90%D7%A0%D7%99%20%D7%A6%D7%A8%D7%99%D7%9A%20%D7%A2%D7%96%D7%A8%D7%94%20%D7%A2%D7%9D%20%D7%94%D7%9E%D7%A2%D7%A8%D7%9B%D7%AA";

export const unitTypes = [
  { text: "קומפ׳", value: "קומפ׳" },
  { text: "מ״ר", value: "מ״ר" },
  { text: "מ״א", value: "מ״א" },
  { text: "קוב", value: "קוב" },
  { text: "יח׳", value: "יח׳" },
  { text: "שעות עבודה", value: "שעות עבודה" },
  { text: "ימי עבודה", value: "ימי עבודה" },
];

export const roles = { 2: "Admin", 3: "User", 4: "Owner" };

export const cloneHints = [
  "שכפול דוח",
  "פרוטוקול ראשוני",
  "פרוטוקול מסירה",
  "בדק חוזר",
  "בקרת מעקב תיקון ממצאים",
];

export const fontFamilyOptions = [
  { text: "Assistant", value: "Assistant" },
  { text: "Rubik", value: "Rubik" },
];

export const dragOptions = {
  animation: 200,
  disabled: false,
  ghostClass: "ghost",
};

export const typography = {
  fontFamily: fontFamilyOptions[0].value,
  minFontSize: 8,
  maxFontSize: 72,
  fontSize: 14,
  textColor: "#000",
  backgroundColor: "#ffffffff",
};

export const placeholders = [
  { text: "[דוח_תאריך_ביקור]", tooltip: "תאריך ביקור", category: "דוח" },
  {
    text: "[דוח_תאריך_עודכן_לאחרונה]",
    tooltip: "תאריך עדכון אחרון",
    category: "דוח",
  },
  { text: "[דוח_שעת_ביקור]", tooltip: "שעת ביקור", category: "דוח" },
  { text: "[דוח_שם_לקוח]", tooltip: "שם הלקוח", category: "דוח" },
  { text: "[דוח_מייל_לקוח]", tooltip: "מייל הלקוח", category: "דוח" },
  { text: "[דוח_כותרת]", tooltip: "כותרת הדוח", category: "דוח" },
  { text: "[דוח_כתובת]", tooltip: "כתובת", category: "דוח" },
  { text: "[דוח_מזהה]", tooltip: "מזהה הדוח", category: "דוח" },
  { text: "[דוח_מספר]", tooltip: "מספר הדוח", category: "דוח" },
  { text: "[פרויקט_מספר]", tooltip: "מספר הפרויקט", category: "פרויקט" },
  { text: "[פרויקט_שם]", tooltip: "שם הפרויקט", category: "פרויקט" },
  { text: "[משתמש_שם]", tooltip: "שם משתמש", category: "משתמש" },
  { text: "[משתמש_אימייל]", tooltip: "מייל משתמש", category: "משתמש" },
  { text: "[משתמש_טלפון]", tooltip: "טלפון משתמש", category: "משתמש" },
  { text: "[משתמש_מקצוע]", tooltip: "מקצוע משתמש", category: "משתמש" },
  { text: "[משתמש_ניסיון]", tooltip: "ניסיון משתמש", category: "משתמש" },
  { text: "[משתמש_השכלה]", tooltip: "השכלה משתמש", category: "משתמש" },
  { text: "[חברה_שם]", tooltip: "שם חברה", category: "כללי" },
  { text: "[תאריך_נוכחי]", tooltip: "תאריך נוכחי", category: "כללי" },
  { text: "[תאריך_נוכחי_עברי]", tooltip: "תאריך עברי נוכחי", category: "כללי" },
  { text: "[עמוד]", tooltip: "עמוד _ מתוך _", category: "כללי" },
];

export const reportTemplateItemTypes = [
  {
    supports: ["html"],
    type: "html",
    name: "HTML",
    icon: "format-title",
    isEditableOnReport: false,
    settings: { label: "html", content: "" },
  },
  {
    supports: ["global"],
    type: "spacer",
    name: "מרווח",
    icon: "format-title",
    isEditableOnReport: false,
    settings: { label: "מרווח", size: 20 },
  },
  {
    supports: ["table", "repeater"],
    type: "text",
    name: "טקסט",
    icon: "format-color-text",
    isEditableOnReport: true,
    settings: { label: "טקסט", content: "" },
  },
  {
    supports: ["global", "table"],
    type: "files",
    name: "קבצים",
    icon: "file-multiple",
    isEditableOnReport: true,
    settings: { label: "קבצים", files: [] },
  },
  {
    supports: ["global", "table"],
    type: "textChoices",
    name: "טקסט עם בחירות",
    icon: "format-color-text",
    settings: {
      label: "טקסט עם בחירות",
      content: "",
      style: "inline",
      align: "right",
      options: [],
    },
    isEditableOnReport: true,
  },
  {
    supports: ["global", "table", "repeater"],
    type: "wysiwyg",
    name: "תיבת טקסט",
    icon: "format-color-text",
    isEditableOnReport: true,
    settings: { label: "תיבת טקסט", content: "" },
  },
  {
    supports: ["repeater"],
    type: "headline",
    name: "כותרת",
    icon: "format-title",
    isEditableOnReport: false,
    settings: { label: "כותרת", headline: null, headlineAlign: "right" },
  },
  {
    supports: ["global"],
    type: "priceTable",
    name: "טבלת מחירים",
    icon: "currency-usd",
    isEditableOnReport: false,
    settings: {
      label: "טבלת מחירים",
      groupBy: "category",
      headline: "הערכה כספית",
      headlineAlign: "right",
      showDefects: true,
      showVat: true,
      totals: [
        {
          text: "בצ״מ (10%)",
          isActive: true,
          value: 10,
          valueType: "percentage",
        },
        {
          text: "פיקוח הנדסי (10%)",
          isActive: true,
          value: 10,
          valueType: "percentage",
        },
      ],
    },
  },
  {
    supports: ["global"],
    type: "pageBreak",
    name: "שבירת עמוד",
    icon: "format-page-break",
    isEditableOnReport: false,
    settings: { label: "שבירת עמוד" },
  },
  {
    supports: ["global", "table", "repeater"],
    type: "images",
    name: "תמונות",
    icon: "image-multiple",
    isEditableOnReport: true,
    settings: {
      label: "תמונות",
      imagesPerRow: 3,
      headline: null,
      headlineAlign: "right",
      editImage: true,
      pageBreakBefore: false,
    },
  },
  {
    supports: ["global"],
    type: "image",
    name: "תמונה בודדת",
    icon: "image",
    isEditableOnReport: true,
    settings: {
      label: "תמונה בודדת",
      imageWidth: 100,
      imageAlign: "center",
      headline: null,
      headlineAlign: "right",
    },
  },
  {
    supports: ["table"],
    type: "signature",
    name: "חתימה",
    icon: "draw",
    isEditableOnReport: true,
    settings: {
      label: "חתימה",
    },
  },
  {
    supports: ["global"],
    type: "sideToSideTwoTexts",
    name: "2 טקסטים מצד לצד",
    icon: "format-text-rotation-none",
    isEditableOnReport: true,
    settings: { label: "2  טקסטים מצד לצד", textRight: null, textLeft: null },
  },
  {
    supports: ["global"],
    type: "defectsTable",
    name: "הצגת ממצאים",
    icon: "view-list",
    isEditableOnReport: false,
    settings: {
      label: "הצגת ממצאים",
      groupBy: "category",
      uploadsPerRow: 2,
      attachmentsPerRow: 1,
      style: "table",
      columns: [
        {
          id: "description",
          name: "ממצא",
          text: "ממצא",
          isActive: true,
        },
        {
          id: "defectStatus",
          name: "מצב ממצא",
          text: "מצב ממצא",
          isActive: true,
        },
        {
          id: "defectRecheckStatus",
          name: "ממצא בדיקה חוזרת",
          text: "ממצא בדיקה חוזרת",
          isActive: true,
        },
        {
          id: "location",
          name: "מיקום",
          text: "מיקום",
          isActive: true,
        },
        {
          id: "category",
          name: "קטגוריה",
          text: "קטגוריה",
          isActive: true,
        },
        {
          id: "note",
          name: "הערה",
          text: "הערה",
          isActive: true,
        },
        {
          id: "recommendation",
          name: "המלצה",
          text: "המלצה",
          isActive: true,
        },
        {
          id: "standard",
          name: "תקן",
          text: "תקן",
          isActive: true,
        },
        {
          id: "price",
          name: "מחיר",
          text: "מחיר",
          isActive: true,
        },
      ],
    },
  },
  {
    supports: ["global"],
    type: "miniDefectsTable",
    name: "טבלת ממצאים מצומצמת",
    icon: "view-agenda-outline",
    isEditableOnReport: false,
    settings: {
      label: "טבלת ממצאים מצומצמת",
      groupBy: "category",
      withIndexColumn: true,
      columns: [
        {
          id: "category",
          name: "קטגוריה",
          text: "קטגוריה",
          columnWidth: 12,
          isActive: true,
        },
        {
          id: "description",
          name: "ממצא",
          text: "ממצא",
          columnWidth: 29,
          isActive: true,
        },
        {
          id: "location",
          name: "מיקום",
          text: "מיקום",
          columnWidth: 12,
          isActive: true,
        },
        {
          id: "recommendation",
          name: "המלצה",
          text: "המלצה",
          columnWidth: 31,
          isActive: true,
        },
        {
          id: "uploads",
          name: "תמונות",
          text: "תמונות",
          columnWidth: 16,
          isActive: true,
        },
      ],
    },
  },
  {
    supports: ["global"],
    type: "defectsSummaryTable",
    name: "טבלת סיכום ממצאים",
    icon: "format-list-numbered-rtl",
    isEditableOnReport: false,
    settings: {
      label: "טבלת סיכום ממצאים",
      headline: "רשימת ממצאים",
      headlineAlign: "center",
      groupBy: "category",
    },
  },
  {
    supports: ["global"],
    type: "repeater",
    name: "צ׳קליסט/טבלאות",
    icon: "format-line-weight",
    isEditableOnReport: true,
    settings: {
      label: "צ׳קליסט/טבלאות",
      pageBreakBetweenRows: true,
      dontPageBreakBetweenRows: false,
      withIndex: false,
      style: "list",
      columns: [],
      rows: [],
    },
  },
  {
    supports: ["global", "repeater"],
    type: "table",
    name: "טבלה",
    icon: "grid",
    isEditableOnReport: true,
    settings: {
      label: "טבלה",
      style: "table",
      headlineAlign: "right",
      pageBreakBetweenRows: false,
      withIndexColumn: true,
      columns: [],
      rows: [],
    },
  },
  {
    supports: ["global"],
    type: "userSignature",
    name: "חתימת משתמש",
    icon: "signature",
    isEditableOnReport: false,
    settings: {
      label: "חתימת משתמש",
      signatureAlign: "left",
    },
  },
  {
    supports: ["global"],
    type: "signatures",
    name: "חתימות",
    icon: "signature",
    isEditableOnReport: true,
    settings: {
      label: "חתימות",
      signaturesAlign: "right", // "right" "left" "center"
      columns: [
        {
          id: "ab3761fd-c490-37ca-496a-71ab5da26b38",
          type: "signature",
          settings: {
            label: "חתימה",
          },
        },
        {
          id: "bd646964-8be4-7134-709a-15efa633efe2",
          type: "text",
          settings: {
            label: "שם",
          },
        },
        {
          id: "505ada10-1bdb-49e9-82b6-07f2d7dbd9f4",
          type: "text",
          settings: {
            label: "תפקיד",
          },
        },
      ],
      rows: [],
    },
  },
  // {
  //   supports: ["global"],
  //   type: "files",
  //   name: "קבצים",
  //   icon: "file",
  //   isEditableOnReport: true,
  //   settings: {
  //     label: "קבצים",
  //   },
  // },
];

export const preferencesLibrary = {
  header: [
    {
      id: "template_0a6df933-dff7-4412-aac2-595df80eb7ea",
      name: "header",
      style: {
        width: 100,
        border: {
          color: "#000",
          direction: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        _height: 86,
        padding: {
          top: 10,
          left: 25,
          right: 25,
          bottom: 10,
          isLocked: false,
        },
      },
      sections: [
        {
          id: "section_fe001621-1389-4cfd-abc5-5e947496dbfb",
          style: {
            border: {
              color: "#000",
              width: {
                bottom: 1,
              },
              direction: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
            },
            padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 5,
            },
          },
          columns: [
            {
              id: "column_2e3d3a59-3d52-4744-80a1-b7269e7ffa7a",
              style: {
                width: 25,
                verticalAlign: "middle",
              },
              fields: [
                {
                  id: "field_26501004-43a7-4962-a4ce-7b809090f4d3",
                  type: "image",
                  style: {
                    width: 100,
                    imageAlign: "right",
                  },
                  imageUrl: "images/uploads/627179e0641e7.png",
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
            {
              id: "column_1530b363-2573-4130-9457-fe4cfaa8024a",
              style: {
                width: 75,
                verticalAlign: "bottom",
              },
              fields: [
                {
                  id: "field_b8be743f-2493-4cbc-90d8-042271a9b44e",
                  text: "<p><strong>שם החברה</strong> | 050-000-0000 | youremail@gmail.com</p>",
                  type: "text",
                  style: {
                    color: "#000000",
                    fontSize: 16,
                    textAlign: "left",
                  },
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
          ],
          elementType: "section",
        },
      ],
      elementType: "paper",
    },
    {
      id: "template_0a6df933-dff7-4412-aac2-595df80eb7ea",
      name: "header",
      style: {
        width: 100,
        border: {
          color: "#000",
          direction: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        _height: 120,
        padding: {
          top: 10,
          left: 25,
          right: 25,
          bottom: 10,
          isLocked: false,
        },
      },
      sections: [
        {
          id: "section_fe001621-1389-4cfd-abc5-5e947496dbfb",
          style: {
            border: {
              color: null,
              width: {
                bottom: 0,
              },
              direction: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
            },
            padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
          },
          columns: [
            {
              id: "column_1530b363-2573-4130-9457-fe4cfaa8024a",
              style: {
                width: 100,
                verticalAlign: "bottom",
              },
              fields: [
                {
                  id: "field_26501004-43a7-4962-a4ce-7b809090f4d3",
                  type: "image",
                  style: {
                    width: 25,
                    imageAlign: "center",
                  },
                  imageUrl: "images/uploads/627179e0641e7.png",
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
          ],
          elementType: "section",
        },
        {
          id: "section_517ebda0-e500-4b08-b0b6-8f17cf6a1973",
          style: {
            border: {
              color: "#000",
              width: {
                bottom: 1,
              },
              direction: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
            },
            padding: {
              top: 5,
              left: 0,
              right: 0,
              bottom: 5,
            },
          },
          columns: [
            {
              id: "column_38fd6597-9a49-4109-97fe-e5cafa6192c4",
              style: {
                width: 100,
                verticalAlign: "middle",
              },
              fields: [
                {
                  id: "field_b8be743f-2493-4cbc-90d8-042271a9b44e",
                  text: "<p><strong>שם החברה</strong> | 050-000-0000 | youremail@gmail.com</p>",
                  type: "text",
                  style: {
                    color: "#000000",
                    fontSize: 16,
                    textAlign: "center",
                  },
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
          ],
          elementType: "section",
        },
      ],
      elementType: "paper",
    },
    {
      id: "template_0a6df933-dff7-4412-aac2-595df80eb7ea",
      name: "header",
      style: {
        width: 100,
        border: {
          color: "#000",
          direction: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        _height: 96,
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 10,
          isLocked: false,
        },
      },
      sections: [
        {
          id: "section_fe001621-1389-4cfd-abc5-5e947496dbfb",
          style: {
            border: {
              color: "#000",
              width: {
                bottom: 1,
              },
              direction: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
            },
            padding: {
              top: 10,
              left: 25,
              right: 25,
              bottom: 10,
              isLocked: false,
            },
            backgroundColor: "#F3F3F3",
          },
          columns: [
            {
              id: "column_2e3d3a59-3d52-4744-80a1-b7269e7ffa7a",
              style: {
                width: 25,
                verticalAlign: "middle",
              },
              fields: [
                {
                  id: "field_26501004-43a7-4962-a4ce-7b809090f4d3",
                  type: "image",
                  style: {
                    width: 100,
                    imageAlign: "right",
                  },
                  imageUrl: "images/uploads/627179e0641e7.png",
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
            {
              id: "column_1530b363-2573-4130-9457-fe4cfaa8024a",
              style: {
                width: 75,
                verticalAlign: "bottom",
              },
              fields: [
                {
                  id: "field_b8be743f-2493-4cbc-90d8-042271a9b44e",
                  text: "<p><strong>שם החברה</strong> | 050-000-0000 | youremail@gmail.com</p>",
                  type: "text",
                  style: {
                    color: "#000000",
                    fontSize: 16,
                    textAlign: "left",
                  },
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
          ],
          elementType: "section",
        },
      ],
      elementType: "paper",
    },
    {
      id: "template_0a6df933-dff7-4412-aac2-595df80eb7ea",
      name: "header",
      style: {
        width: 100,
        border: {
          color: "#000",
          direction: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        _height: 93,
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 10,
          isLocked: false,
        },
      },
      sections: [
        {
          id: "section_fe001621-1389-4cfd-abc5-5e947496dbfb",
          style: {
            border: {
              color: null,
              width: {
                bottom: 0,
              },
              direction: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
            },
            padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              isLocked: true,
            },
            backgroundColor: null,
          },
          columns: [
            {
              id: "column_2e3d3a59-3d52-4744-80a1-b7269e7ffa7a",
              style: {
                width: 100,
                verticalAlign: "middle",
              },
              fields: [
                {
                  id: "field_26501004-43a7-4962-a4ce-7b809090f4d3",
                  type: "image",
                  style: {
                    width: 100,
                    imageAlign: "center",
                  },
                  imageUrl: "images/uploads/62717a68715c1.png",
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
          ],
          elementType: "section",
        },
      ],
      elementType: "paper",
    },
  ],
  footer: [
    {
      id: "template_6ea6ace5-e087-40bf-afe8-50bf84ef21c0",
      name: "footer",
      style: {
        width: 100,
        border: {
          color: "#000",
          direction: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        _height: 78,
        padding: {
          top: 10,
          left: 25,
          right: 25,
          bottom: 0,
        },
      },
      sections: [
        {
          id: "section_1fe2e555-3a00-49e7-b356-30713256b765",
          style: {
            width: 100,
            border: {
              top: 3,
              left: 5,
              color: "#000",
              style: "solid",
              width: {
                top: 1,
                left: 0,
                right: 0,
                bottom: 0,
                isLocked: false,
              },
              isLocked: false,
              direction: {
                top: 3,
                left: 3,
                right: 3,
                bottom: 3,
              },
            },
            padding: {
              top: 5,
              left: 5,
              right: 5,
              bottom: 5,
              isLocked: false,
            },
            backgroundColor: null,
          },
          columns: [
            {
              id: "column_084708a0-797a-4fba-a621-a561f85f34b4",
              style: {
                width: 33,
                border: {
                  color: "#000",
                  direction: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  },
                },
                padding: {
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                },
                verticalAlign: "top",
              },
              fields: [
                {
                  id: "field_fa6aa338-e322-4017-bded-ba7a3403c353",
                  text: "<p>[עמוד]</p>",
                  type: "text",
                  style: {
                    color: "#000000",
                    fontSize: "14",
                    textAlign: "right",
                    fontWeight: "normal",
                  },
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
            {
              id: "column_f1dc897d-5e69-4763-92aa-4554653e15f7",
              style: {
                width: 36.35917312661494,
                border: {
                  color: "#000",
                  direction: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  },
                },
                padding: {
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                },
              },
              fields: [
                {
                  id: "field_1b5d8b5a-bfd6-4418-9fa5-b58843a75628",
                  text: "<p><strong>שם החברה</strong></p>",
                  type: "text",
                  style: {
                    color: "#000000",
                    fontSize: "14",
                    textAlign: "center",
                    fontWeight: "normal",
                  },
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
            {
              id: "column_09567473-4623-4985-a688-b6a1bbc12e8d",
              style: {
                width: 30.640826873385027,
                border: {
                  color: "#000",
                  direction: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  },
                },
                padding: {
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                },
              },
              fields: [
                {
                  id: "field_db18f9af-5a2a-4495-8cfa-713eee06c048",
                  text: "<p>[תאריך_נוכחי]</p>",
                  type: "text",
                  style: {
                    color: "#000000",
                    fontSize: "14",
                    textAlign: "left",
                    fontWeight: "normal",
                  },
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
          ],
          elementType: "section",
        },
      ],
      elementType: "paper",
    },
    {
      id: "template_6ea6ace5-e087-40bf-afe8-50bf84ef21c0",
      name: "footer",
      style: {
        width: 100,
        border: {
          color: "#000",
          direction: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        _height: 68,
        padding: {
          top: 25,
          left: 0,
          right: 0,
          bottom: 0,
        },
      },
      sections: [
        {
          id: "section_1fe2e555-3a00-49e7-b356-30713256b765",
          style: {
            width: 100,
            border: {
              top: 3,
              left: 5,
              color: "#000",
              style: "solid",
              width: {
                top: 1,
                left: 0,
                right: 0,
                bottom: 0,
                isLocked: false,
              },
              isLocked: false,
              direction: {
                top: 3,
                left: 3,
                right: 3,
                bottom: 3,
              },
            },
            padding: {
              top: 10,
              left: 25,
              right: 25,
              bottom: 10,
              isLocked: false,
            },
            backgroundColor: "#F3F3F3",
          },
          columns: [
            {
              id: "column_084708a0-797a-4fba-a621-a561f85f34b4",
              style: {
                width: 33,
                border: {
                  color: "#000",
                  direction: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  },
                },
                padding: {
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                },
                verticalAlign: "top",
              },
              fields: [
                {
                  id: "field_fa6aa338-e322-4017-bded-ba7a3403c353",
                  text: "<p>[עמוד]</p>",
                  type: "text",
                  style: {
                    color: "#000000",
                    fontSize: "14",
                    textAlign: "right",
                    fontWeight: "normal",
                  },
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
            {
              id: "column_f1dc897d-5e69-4763-92aa-4554653e15f7",
              style: {
                width: 36.35917312661494,
                border: {
                  color: "#000",
                  direction: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  },
                },
                padding: {
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                },
              },
              fields: [
                {
                  id: "field_1b5d8b5a-bfd6-4418-9fa5-b58843a75628",
                  text: "<p><strong>שם החברה</strong></p>",
                  type: "text",
                  style: {
                    color: "#000000",
                    fontSize: "14",
                    textAlign: "center",
                    fontWeight: "normal",
                  },
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
            {
              id: "column_09567473-4623-4985-a688-b6a1bbc12e8d",
              style: {
                width: 30.640826873385027,
                border: {
                  color: "#000",
                  direction: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  },
                },
                padding: {
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                },
              },
              fields: [
                {
                  id: "field_db18f9af-5a2a-4495-8cfa-713eee06c048",
                  text: "<p>[תאריך_נוכחי]</p>",
                  type: "text",
                  style: {
                    color: "#000000",
                    fontSize: "14",
                    textAlign: "left",
                    fontWeight: "normal",
                  },
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
          ],
          elementType: "section",
        },
      ],
      elementType: "paper",
    },
    {
      id: "template_6ea6ace5-e087-40bf-afe8-50bf84ef21c0",
      name: "footer",
      style: {
        width: 100,
        border: {
          color: "#000",
          direction: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        _height: 150,
        padding: {
          top: 10,
          left: 0,
          right: 0,
          bottom: 0,
        },
      },
      sections: [
        {
          id: "section_7235ff4b-38f0-4d6b-a57b-a40a93eb890c",
          style: {
            border: {
              color: "#000",
              direction: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
            },
            padding: {
              top: 0,
              left: 25,
              right: 25,
              bottom: 10,
            },
            backgroundColor: null,
          },
          columns: [
            {
              id: "column_6c226378-bae7-492b-a5bb-f27faa595fd8",
              style: {
                width: 100,
                verticalAlign: "middle",
              },
              fields: [
                {
                  id: "field_8ee37f9b-4b15-44ac-ace5-06f7f001c1db",
                  text: "<p>[עמוד]</p>",
                  type: "text",
                  style: {
                    color: "#000000",
                    fontSize: 16,
                    textAlign: "left",
                  },
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
          ],
          elementType: "section",
        },
        {
          id: "section_1fe2e555-3a00-49e7-b356-30713256b765",
          style: {
            width: 100,
            border: {
              top: 3,
              left: 5,
              color: null,
              style: "solid",
              width: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                isLocked: false,
              },
              isLocked: false,
              direction: {
                top: 3,
                left: 3,
                right: 3,
                bottom: 3,
              },
            },
            padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              isLocked: false,
            },
            backgroundColor: null,
          },
          columns: [
            {
              id: "column_09567473-4623-4985-a688-b6a1bbc12e8d",
              style: {
                width: 99.99999999999996,
                border: {
                  color: "#000",
                  direction: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  },
                },
                padding: {
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                },
              },
              fields: [
                {
                  id: "field_19c12f85-a9a6-4a3d-9cae-256464f9836a",
                  type: "image",
                  style: {
                    width: 100,
                    imageAlign: "center",
                  },
                  imageUrl: "images/uploads/62717a3e790fb.png",
                  elementType: "field",
                },
              ],
              elementType: "column",
            },
          ],
          elementType: "section",
        },
      ],
      elementType: "paper",
    },
  ],
};

export const supportVideos = [
  { id: "sY-bK-hM5Uo", categories: ["all", "עזרה במערכת"] },
  { id: "oJ-GbCx3rME", categories: ["all", "בדק בית"] },
  { id: "A4pd-m-elYU", categories: ["all", "פיקוח בניה"] },
  { id: "VjAkmIvE1Rs", categories: ["all", "פיקוח בניה"] },
  { id: "u_qDQjQs_AU", categories: ["all", "אדריכלים"] },
  { id: "m75WcBTD7_4", categories: ["all", "יועצים"] },
  { id: "-Od0ippi1p4", categories: ["all", "פיקוח בניה"] },
  { id: "2Q39R0B5IRQ", categories: ["all", "יועצים"] },
  { id: "8aEOPgF_jeo", categories: ["all", "איתור נזילות"] },
  { id: "sZdVnzfde2M", categories: ["all", "יועצים"] },
  { id: "t5xRby_0Dpo", categories: ["all", "עזרה במערכת"] },
  { id: "RZRMPCE836E", categories: ["all", "עזרה במערכת"] },
  { id: "to5iuRm7qFw", categories: ["all", "עזרה במערכת"] },
  { id: "zohx-ZBOTHk", categories: ["all", "עזרה במערכת"] },
  { id: "u-MYM2tsyVU", categories: ["all", "עזרה במערכת"] },
  { id: "rNLvDmDJ5v4", categories: ["all", "עזרה במערכת"] },
  { id: "gYhyOF4twVI", categories: ["all", "עזרה במערכת"] },
  { id: "SKBtSF78uOM", categories: ["all", "עזרה במערכת"] },
  { id: "RGY3gG5ri-s", categories: ["all", "עזרה במערכת"] },
  { id: "QN00ySBBnBo", categories: ["all", "עזרה במערכת"] },
  { id: "MX4_8MZCw9A", categories: ["all", "עזרה במערכת"] },
  { id: "DG5C7Y3utZg", categories: ["all", "עזרה במערכת"] },
  { id: "6sNMLkG8npk", categories: ["all", "עזרה במערכת"] },
  { id: "5ZQao7PEgzc", categories: ["all", "עזרה במערכת"] },
  { id: "19tLn8l9Hxo", categories: ["all", "עזרה במערכת"] },
  { id: "0N3BHXPQLEE", categories: ["all", "עזרה במערכת"] },
  { id: "3J3xMGzbqsw", categories: ["all", "עזרה במערכת"] },
  { id: "mFg60IxJ37A", categories: ["all", "עזרה במערכת"] },
];

export const homeSupportVideos = [
  { id: "oJ-GbCx3rME", title: "איך מפיקים דוח בדק בית?" },
  { id: "u_qDQjQs_AU", title: "איך אדריכלים משתמשים ברפורטו?" },
  { id: "m75WcBTD7_4", title: "הפקת דוחות קונסטרוקציה" },
  { id: "-Od0ippi1p4", title: "הפקת דוחות פיקוח דיירים" },
  { id: "0N3BHXPQLEE", title: "הסבר על שדה תמונות" },
  { id: "SKBtSF78uOM", title: "אפשרויות להוספת תמונות בדוח" },
  { id: "QN00ySBBnBo", title: "תמונות בתוך טבלה" },
  { id: "MX4_8MZCw9A", title: "איך מעדכנים פרטי אשראי" },
];

export const reportMetaDataKeys = [
  "name",
  "title",
  "number",
  "address",
  "isDraft",
  "stockId",
  "visitDate",
  "visitTime",
  "customerName",
  "customerEmail",
  "customerPhone",
  "description",
  "pdf",
  "category",
  "itemPaddingTop",
  "itemPaddingBottom",
  "enableDefectStatus",
  "enableDefectRecheckStatus",
  "tag",
  "defectsGroupedBy",
  "isDisabled",
  "previewPdfImage",
];

export const DEFAULT_MAX_IMAGE_SIZE = 1400;

export const TESTS_COMPANY_ID = 2;

export const LIBRARY_COMPANY_ID = 186;
