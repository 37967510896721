<template>
  <v-card v-if="upload" outlined class="edit-image-modal">
    <v-card-title class="flex items-center justify-between flex-wrap mb-0">
      <span>עריכת תמונה</span>
      <v-btn
        icon
        small
        flat
        class="mr-2 max-h-6 max-w-6 bg-inherit"
        @click="emit('close-edit-image')"
      >
        <v-icon size="15">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <div class="canvas-container">
      <EditImageToolbar
        :imageLayerRef="imageLayerRef"
        @close-edit-image="emit('close-edit-image')"
      />

      <div
        class="edit-image-canvas relative"
        id="canvas-container"
        :class="{ 'cursor-move': !state.activeTool }"
      >
        <v-stage
          ref="stageRef"
          :config="state.stageConfig.value"
          @wheel="options.handleZoom"
          @mousedown="options.startDrawing"
          @mousemove="options.draw"
          @mouseup="options.finishDrawing"
          @touchstart="options.onTouchStart"
          @touchmove="options.onTouchMove"
          @touchend="options.onTouchEnd"
        >
          <v-layer ref="imageLayerRef">
            <v-image
              :image="state.image.value"
              :x="state.imageX.value"
              :y="state.imageY.value"
              :width="state.imageWidth.value"
              :height="state.imageHeight.value"
              :draggable="canDragImage"
              @dragmove="options.constrainImage"
            />
          </v-layer>

          <v-layer ref="shapesLayerRef"></v-layer>
        </v-stage>
      </div>

      <div
        class="mx-4 mt-auto min-h-[100px] flex flex-col justify-end items-end"
        :class="{
          'mb-8': isMobile(),
          'mb-4': !isMobile(),
        }"
        v-if="!state.activeOption.value"
      >
        <v-btn
          block
          class="save-btn depressed"
          color="purple"
          x-large
          @click="finishEditImage"
        >
          שמירת תמונה
        </v-btn>
      </div>
      <EditImageTools v-else />
    </div>
  </v-card>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted } from "vue";
import { useEditImage } from "@/composables/useEditImage";
import EditImageToolbar from "@/components/EditImageModal-v3/EditImageToolbar.vue";
import { MAX_IMAGE_SIZE } from "@/components/EditImageModal-v3/const";
import EditImageTools from "@/components/EditImageModal-v3/EditImageTools.vue";
import { isMobile } from "@/common/helpers/platform";

const props = defineProps({
  upload: Object,
  closeEditImage: Function,
  updateEditImage: Function,
  mediaUrl: String,
});

const stageRef = ref(null);
const imageLayerRef = ref(null);
const shapesLayerRef = ref(null);

const { options, state } = useEditImage();

const emit = defineEmits(["update-edit-image", "close-edit-image"]);

onMounted(() => {
  const refs = {
    stageRef,
    imageLayerRef,
    shapesLayerRef,
  };

  options.init(refs, { ...props.upload, mediaUrl: props.mediaUrl });
});

onUnmounted(() => {
  options.destroyCanvas();
  stageRef.value = null;
  imageLayerRef.value = null;
  shapesLayerRef.value = null;
});

const canDragImage = computed(() => {
  if (state.activeOption.value?.id !== "crop") return false;

  const scale = state.stageConfig.value.scaleX;
  const scaledWidth = state.imageWidth.value * scale;
  const scaledHeight = state.imageHeight.value * scale;
  const canvasWidth = state.stageConfig.value.width;
  const canvasHeight = state.stageConfig.value.height;

  // Allow dragging if image overflows canvas in either dimension
  return scaledWidth > canvasWidth || scaledHeight > canvasHeight;
});

const finishEditImage = () => {
  const stageNode = stageRef.value.getStage();

  let finalW, finalH;
  if (state.isSquare.value) {
    const side = Math.min(
      MAX_IMAGE_SIZE,
      state.originalWidth.value,
      state.originalHeight.value
    );
    finalW = side;
    finalH = side;
  } else {
    const w = state.originalWidth.value;
    const h = state.originalHeight.value;
    if (w >= h) {
      // landscape
      if (w > MAX_IMAGE_SIZE) {
        const ratio = MAX_IMAGE_SIZE / w;
        finalW = MAX_IMAGE_SIZE;
        finalH = Math.round(h * ratio);
      } else {
        finalW = w;
        finalH = h;
      }
    } else {
      // portrait
      if (h > MAX_IMAGE_SIZE) {
        const ratio = MAX_IMAGE_SIZE / h;
        finalH = MAX_IMAGE_SIZE;
        finalW = Math.round(w * ratio);
      } else {
        finalW = w;
        finalH = h;
      }
    }
  }

  const pixelRatioX = finalW / state.stageConfig.value.width;
  const pixelRatioY = finalH / state.stageConfig.value.height;

  // Usually these are very close, but pick min just to avoid distortions:
  const pixelRatio = Math.min(pixelRatioX, pixelRatioY);

  const dataURL = stageNode.toDataURL({
    mimeType: "image/jpeg", // Set the MIME type to JPEG
    pixelRatio: pixelRatio,
    quality: 1,
  });

  emit("update-edit-image", dataURL);
  emit("close-edit-image");
};
</script>

<style lang="scss">
.edit-image-dialog {
  @media (max-width: 700px) {
    margin: 0 !important;
    .v-overlay__content {
      height: 100vh;
      max-height: 100vh !important;

      height: 100dvh;

      min-height: 100dvh !important;
      margin: 0 !important;
    }

    .edit-image-modal {
      background: #000 !important;

      .v-card-title {
        display: none;
      }
    }

    .canvas-container {
      height: 100vh;
      height: 100dvh;
      display: flex;
      flex-direction: column;
    }
  }
}

.edit-image-modal {
  .edit-image-canvas {
    flex-grow: 2;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 0;
    @media (max-width: 700px) {
      min-height: 100vw;
    }
  }

  .save-btn {
    background-color: var(--color-purple) !important;
    height: 44px;
    max-height: 44px;
    @media (max-width: 550px) {
      margin-inline-end: 4px;
    }
  }
}

.edit-image-canvas,
.konvajs-content,
#canvas-container {
  touch-action: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  user-select: none;
}
</style>
