<template>
  <div class="page-bg-gradient">
    <div style="max-width: 1440px;padding: 1rem;" class="mx-auto h-full" v-if="isLoaded">
      <div class="mb-5">
        <HomeTrialBanner />
      </div>

      <div class="mb-5">
        <HomeScheduleDemo />
      </div>

      <div class="mb-5">
        <HomeReportStatusesBanner />
      </div>
      <div class="mb-5">
        <HomePrimaryVideoCard />
      </div>

      <div class="mb-5">
        <HomeReports />
      </div>
      <div class="mb-5">
        <HomeProjects />
      </div>
      <div class="mb-5">
        <HomeVideosCard />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeVideosCard from "./components/HomeVideosCard.vue";
import HomeReports from "./components/HomeReports.vue";
import HomeTrialBanner from "./components/HomeTrialBanner.vue";
import HomeScheduleDemo from "./components/HomeScheduleDemo.vue";
import HomeProjects from "./components/HomeProjects.vue";
import HomePrimaryVideoCard from "./components/HomePrimaryVideoCard.vue";
import HomeReportStatusesBanner from "./components/HomeReportStatusesBanner.vue";
export default {
  components: {
    HomePrimaryVideoCard,
    HomeProjects,
    HomeScheduleDemo,
    HomeTrialBanner,
    HomeReports,
    HomeVideosCard,
    HomeReportStatusesBanner,
  },
  data() {
    return {
      isLoaded: true,
      reports: [],
    };
  },
  computed: {
    ...mapGetters("reportTemplates", ["reportTemplates"]),
  },
  async created() {
    this.isLoaded = false;
    let loader = this.$loading.show();
    await this.$store.dispatch("reportTemplates/fetchReportTemplates");
    this.isLoaded = true;
    loader.hide();
  },
};
</script>
