async function getColors(payload) {
    const response = await window.axios.get(
        `/api/v1/report-statuses/get-allowed-colors`,
        payload
    );
    return response.data?.data?.colors;
}

async function getStatuses(payload) {
    const response = await window.axios.get(`/api/v1/report-statuses`, payload);
    return response.data?.data?.statuses;
}

async function createStatus(status) {
    const response = await window.axios.post(`/api/v1/report-statuses/`, status);
    return response.data?.data;
}

async function updateStatus(status) {
    const response = await window.axios.put(`/api/v1/report-statuses/${status.id}`, status);
    return response.data?.data;
}

async function removeStatus(statusId) {
    const response = await window.axios.delete(
        `/api/v1/report-statuses/${statusId}`
    );
    return response.data;
}

async function updateOrder(payload) {
    const response = await window.axios.put(
        `/api/v1/report-statuses/update-order`,
        payload
    );
    return response.data;
}


export default {
    getColors,
    getStatuses,
    createStatus,
    updateStatus,
    removeStatus,
    updateOrder,
};
