<template>
  <div class="app-field app-field--wysiwyg">
    <label v-if="label" class="mb-2 d-block subtitle-2">{{ label }}</label>
    <div class="main-container">
      <div
        class="editor-container editor-container_classic-editor"
        ref="editorContainerElement"
      >
        <div class="editor-container__editor">
          <div ref="editorElement">
            <ckeditor
              v-model="editorData"
              :editor="editor"
              :config="config"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  Autosave,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Indent,
  IndentBlock,
  Italic,
  List,
  Paragraph,
  PasteFromOffice,
  SelectAll,
  SpecialCharacters,
  Strikethrough,
  TextTransformation,
  Underline,
  Undo,
  ParagraphButtonUI,
  HeadingButtonsUI,
  Heading,
} from "ckeditor5";

// import translations from "ckeditor5/translations/he.js";

import "ckeditor5/ckeditor5.css";

export default {
  name: "RFieldWysiwyg2",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editorData: "",
      config: {
        removePlugins : ['scayt'],
        plugins: [
          AccessibilityHelp,
          Alignment,
          Autoformat,
          Autosave,
          Bold,
          Essentials,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Indent,
          IndentBlock,
          Italic,
          List,
          Paragraph,
          PasteFromOffice,
          SelectAll,
          SpecialCharacters,
          Strikethrough,
          TextTransformation,
          Underline,
          Undo,
          ParagraphButtonUI,
          HeadingButtonsUI,
          Heading,
        ],
        // fontFamily: {
        //   supportAllValues: true,
        // },
        // fontSize: {
        //   options: [10, 12, 14, "default", 18, 20, 22],
        //   supportAllValues: true,
        // },
        // fontColor: {
        //   format: "hex",
        //   colorPicker: false,
        //   colors: [
        //     {
        //       color: "#BFEDD2",
        //       label: "ירוק בהיר",
        //     },
        //     {
        //       color: "#FBEEB8",
        //       label: "צהוב בהיר",
        //     },
        //     {
        //       color: "#F8CAC6",
        //       label: "אדום בהיר",
        //     },
        //     {
        //       color: "#ECCAFA",
        //       label: "סגול בהיר",
        //     },
        //     {
        //       color: "#C2E0F4",
        //       label: "כחול בהיר",
        //     },
        //     {
        //       color: "#2DC26B",
        //       label: "ירוק",
        //     },
        //     {
        //       color: "#F1C40F",
        //       label: "צהוב",
        //     },
        //     {
        //       color: "#E03E2D",
        //       label: "אדום",
        //     },
        //     {
        //       color: "#B96AD9",
        //       label: "סגול",
        //     },
        //     {
        //       color: "#3598DB",
        //       label: "כחול",
        //     },
        //     {
        //       color: "#169179",
        //       label: "טורקיז",
        //     },
        //     {
        //       color: "#E67E23",
        //       label: "כתום",
        //     },
        //     {
        //       color: "#BA372A",
        //       label: "אדום כהה",
        //     },
        //     {
        //       color: "#843FA1",
        //       label: "סגול כהה",
        //     },
        //     {
        //       color: "#236FA1",
        //       label: "כחול כהה",
        //     },
        //     {
        //       color: "#ECF0F1",
        //       label: "אפור בהיר",
        //     },
        //     {
        //       color: "#CED4D9",
        //       label: "אפור בהיר",
        //     },
        //     {
        //       color: "#95A5A6",
        //       label: "אפור",
        //     },
        //     {
        //       color: "#7E8C8D",
        //       label: "אפור כהה",
        //     },
        //     {
        //       color: "#34495E",
        //       label: "כחול כהה",
        //     },
        //     {
        //       color: "#000000",
        //       label: "שחור",
        //     },
        //     {
        //       color: "#ffffff",
        //       label: "לבן",
        //     },
        //   ],
        // },
        // fontBackgroundColor: {
        //   format: "hex",
        //   colorPicker: false,
        //   colors: [
        //     {
        //       color: "#BFEDD2",
        //       label: "ירוק בהיר",
        //     },
        //     {
        //       color: "#FBEEB8",
        //       label: "צהוב בהיר",
        //     },
        //     {
        //       color: "#F8CAC6",
        //       label: "אדום בהיר",
        //     },
        //     {
        //       color: "#ECCAFA",
        //       label: "סגול בהיר",
        //     },
        //     {
        //       color: "#C2E0F4",
        //       label: "כחול בהיר",
        //     },
        //     {
        //       color: "#2DC26B",
        //       label: "ירוק",
        //     },
        //     {
        //       color: "#F1C40F",
        //       label: "צהוב",
        //     },
        //     {
        //       color: "#E03E2D",
        //       label: "אדום",
        //     },
        //     {
        //       color: "#B96AD9",
        //       label: "סגול",
        //     },
        //     {
        //       color: "#3598DB",
        //       label: "כחול",
        //     },
        //     {
        //       color: "#169179",
        //       label: "טורקיז",
        //     },
        //     {
        //       color: "#E67E23",
        //       label: "כתום",
        //     },
        //     {
        //       color: "#BA372A",
        //       label: "אדום כהה",
        //     },
        //     {
        //       color: "#843FA1",
        //       label: "סגול כהה",
        //     },
        //     {
        //       color: "#236FA1",
        //       label: "כחול כהה",
        //     },
        //     {
        //       color: "#ECF0F1",
        //       label: "אפור בהיר",
        //     },
        //     {
        //       color: "#CED4D9",
        //       label: "אפור בהיר",
        //     },
        //     {
        //       color: "#95A5A6",
        //       label: "אפור",
        //     },
        //     {
        //       color: "#7E8C8D",
        //       label: "אפור כהה",
        //     },
        //     {
        //       color: "#34495E",
        //       label: "כחול כהה",
        //     },
        //     {
        //       color: "#000000",
        //       label: "שחור",
        //     },
        //     {
        //       color: "#ffffff",
        //       label: "לבן",
        //     },
        //   ],
        // },
        // language: "he",
        // placeholder: "",
        // translations: [translations],
      },
      editor: ClassicEditor,
    };
  },
  mounted() {
    let protectedModelValue = this.modelValue;
    if (typeof protectedModelValue === "object") {
      protectedModelValue = "";
    }
    this.editorData = protectedModelValue;
  },

  methods: {
    onEditorReady(editor) {
      // Get the editing view
      const view = editor.editing.view;
      const doc = editor.model.document;

      // Listen to changes in the document
      doc.on('change:data', () => {
        const newValue = editor.getData();
        if (typeof newValue === 'string' && newValue !== this.modelValue) {
          this.$emit('update:modelValue', newValue);
        }
      });
    }
  },
};
</script>

<style lang="scss">
.ck.ck-powered-by {
  display: none;
}

.ck.ck-button.ck-on,
.ck.ck-button.ck-on:hover,
.ck.ck-button.ck-on:focus,
.ck.ck-button.ck-on:active {
  --ck-color-button-on-color: var(--ck-color-text);
  --ck-color-button-on-background: #f5f5f5;
}

.ck-editor {
  --ck-focus-ring: 1px solid var(--color-purple);
}

// ADDED BY TAL FOR CKEDITOR
.ck-editor {

  h1.h0 {
    font-size: 3.4em;
    margin: 0.4em 0;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight:700
  }
  ul {
    // revert ul back to normal
    list-style-type: disc;
    margin-right: 1.5em;
  }
  ol {
    // revert ul back to normal
    list-style-type: decimal;
    margin-right: 1.5em;
  }
  p {
    margin-bottom:10px;
  }
}

</style>
