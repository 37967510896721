<template>
  <section
    class="edit-image-tools-container flex flex-col gap-3 px-4 mt-auto pt-4"
    :class="{
      'mb-8 ': isMobile(),
      'mb-4 ': !isMobile(),
    }"
  >
    <div class="main-tools w-full flex items-center" v-if="tools?.length">
      <v-btn
        v-for="tool in tools"
        :key="tool.id"
        :color="isActive(tool.type) ? 'purple' : 'grey'"
        class="toolbar-action-btn"
        flat
        @click="onSelectTool(tool.type)"
        :shape="isMobile() ? 'round' : ''"
        :icon="isMobile()"
      >
        <v-icon :size="isMobile() ? 25 : 15">{{ tool.icon }}</v-icon>
        <span class="label mx-2">{{ getToolLabel(tool.label) }}</span>
      </v-btn>
    </div>
    <div
      v-if="activeTool && (showTextOptions || showColorsOptions)"
      class="edit-image-tools flex flex-col justify-center gap-3 items-center w-full"
    >
      <div v-if="showTextOptions" class="flex items-center gap-2">

        <v-btn
          v-for="size in fontSizes"
          :key="size.value"
          class="toolbar-action-btn text-white"
          flat
          :color="fontSize === size.value ? 'purple' : 'grey'"
          @click="fontSize = size.value"
        >
          {{ size.title }}
        </v-btn>

        <v-btn
          class="toolbar-action-btn bold-btn"
          :color="isBold ? 'purple' : 'grey'"
          small
          flat
          icon
          shape="round"
          @click="onToggleBold"
        >
          B
        </v-btn>
      </div>

      <div v-if="showColorsOptions" class="edit-image-tools-colors">
        <span
          v-for="color in colors"
          :key="color"
          :data-color="color"
          :class="{ active: currentColor === color }"
          :style="{ backgroundColor: color }"
          @click="onSetColor(color)"
        ></span>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";
import { useEditImage } from "@/composables/useEditImage";
import {
  colors,
  fontSizes,
  toolType,
} from "@/components/EditImageModal-v3/const";
import { isMobile } from "@/common/helpers/platform";

const props = defineProps({
  imageLayerRef: Object,
});

const emit = defineEmits(["close-edit-image"]);

const {
  state: { activeTool, activeOption, currentColor, fontSize, isBold, isSquare },
  options: {
    selectOption,
    toggleAspectRatio,
    setColor,
    selectTool,
    toggleBold,
    resetCanvas,
    rotateCanvas,
  },
} = useEditImage();

// Tools configuration
const tools = computed(() => {
  if (!activeOption.value) return [];
  return activeOption.value.tools;
});

const onSelectTool = (tool) => {
  if (tool === toolType.ratio) {
    toggleAspectRatio();
  } else if (tool === toolType.rotate) {
    rotateCanvas(props.imageLayerRef);
  } else if (tool === toolType.reset) {
    resetCanvas();
    selectOption(null);
  } else if (tool === toolType.zoom) {
    if (activeTool.value === toolType.zoom) {
      tool = null;
    }
  }
  selectTool(tool);
};
// Event handlers
const onSetColor = (color) => setColor(color);
const onToggleBold = () => toggleBold();
const getToolLabel = (label) => {
  if (typeof label === "string") return label;
  else return label(isSquare.value);
};

// Dynamic options for UI
const showColorsOptions = computed(() => activeOption.value?.showColors);
const showTextOptions = computed(() => activeOption.value?.showFontSizes);

const isActive = (type) => {
  if (type === toolType.ratio) return isSquare.value;
  const isActive = activeTool.value && activeTool.value === type;
  return isActive;
};
</script>

<style lang="scss">
.edit-image-tools-container {
  height: 100px;
  justify-content: center;
  @media (min-width: 600px) {
    justify-content: space-evenly;
  }

  .main-tools {
    justify-content: space-evenly;
    @media (min-width: 600px) {
      gap: 0.75rem;
      justify-content: center;
    }
  }

  .edit-image-tools-colors span {
    width: 24px !important;
    height: 24px !important;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 6px 6px !important;
    border: 1px solid white;

    transition: 0.2s;
    &.active {
      transform: scale(1.2) !important;
    }
    @media (min-width: 600px) {
      border: 1px solid var(--color-gray-700);
      margin: 0 6px 0 !important;
    }
  }

  .font-size-select {
    background-color: rgb(33, 33, 33) !important;
    color: white;
    border: 1px solid white;
    border-radius: 4px;
    width: 90px;
    font-size: 0.75rem;
    cursor: pointer;
    height: 48px;

    .v-field {
      --v-input-control-height: 48px !important;
      height: 48px !important;
      --v-field-padding-end: 0 !important;
      padding-inline-end: 3px !important;

      &__input {
        --v-input-control-height: 48px !important;
        min-height: 48px !important;
        padding-block: 0 !important;
      }
    }

    @media (min-width: 600px) {
      background-color: white !important;
      color: var(--color-gray-700);
      border: 1px solid var(--color-gray-700);
      height: 36px;

      .v-field {
        --v-input-control-height: 36px !important;
        height: 36px !important;

        &__input {
          --v-input-control-height: 36px !important;
          min-height: 36px !important;
        }
      }
    }
  }

  .toolbar-action-btn {
    max-height: 48px;
    max-width: 48px;
    background-color: rgb(33, 33, 33) !important;
    .label {
      display: none;
    }
    @media (min-width: 600px) {
      max-height: 30px;
      max-width: unset;
      min-width: auto;
      background-color: var(--color-gray-300) !important;
      color: var(--color-gray-700) !important;
      .label {
        display: unset;
      }
      &.bold-btn {
        max-width: 30px;
        min-width: 30px;
      }
    }
  }

  .toolbar-action-btn.bg-purple {
    background-color: var(--color-purple) !important;
    @media (min-width: 600px) {
      color: white !important;
    }
  }
}
</style>
