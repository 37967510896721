<template>
  <div class="min-h-screen bg-gray-50 p-8" dir="rtl">
    <br><br>
   <b> נא להקליד בתיבה סתם טקסט כמו שאתה מקליד בתוך דוח והטקסט משוכפל פעמיים.
   </b>
    <br><br>
    <RFieldWysiwyg2 label="בדיקה 332" />
    <br><br><br>
    <b>תוצאה:</b>
    <br>
    {{ data2 }}
    <br><br><br>
  </div>
</template>

<script>
import RFieldWysiwyg2 from "@/ui/RFieldWysiwyg2.vue";

export default {
  components: {RFieldWysiwyg2},
  data() {
    return {
      data1: "בדיקה 1",
      data2: "בדיקה 2",
      data3: "בדיקה 3",
    }
  },
  methods: {
    updateData2({key, value}) {
      this.data2 = value;
    },
    updateData3({key, value}) {
      this.data3 = value;
    }
  }
}
</script>
