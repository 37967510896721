<template>
  <div>
    <v-row>
      <v-col cols="12">
        <RFieldEditor
          v-model="localItem.settings.textRight"
          :items="['bold', 'italic', 'underline']"
          label="טקסט צד ימין"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <RFieldEditor
          v-model="localItem.settings.textLeft"
          :items="['bold', 'italic', 'underline']"
          label="טקסט צד שמאל"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      localItem: null,
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
  },
};
</script>
